import { AssetDigest } from "assets";
import _ from "lodash";
import {
  Record,
  RecordType,
  useGetUsedAssetIdsOnOutageScopeRecordsByRecordsEventId,
  useGetUsedRecordsEventIdsOnOutageScopeRecordsByAssetId,
} from "records";

export function recordHasStatusDerivedFromRecommendations(record: Record) {
  return (
    record.recordType === RecordType.ValveDiagnostic ||
    record.recordType === RecordType.FieldInspection ||
    record.recordType === RecordType.Activity ||
    record.recordType === RecordType.CandSU ||
    record.recordType === RecordType.Proactive ||
    record.recordType === RecordType.Predictive ||
    record.recordType === RecordType.Preventative ||
    record.recordType === RecordType.Reactive ||
    record.recordType === RecordType.VibrationAnalysis ||
    record.recordType === RecordType.EquipmentHealth
  );
}

export const singleAssetRecordTypes = [RecordType.FieldInspection, RecordType.OutageScope, RecordType.ValveDiagnostic];

export const isSingleAssetRecordType = (recordType: RecordType) => singleAssetRecordTypes.indexOf(recordType) > -1;

export const recordMustHaveSingleAsset = (record: Record) => isSingleAssetRecordType(record.recordType);

export function recordHasGeneratedDescription(record: Record) {
  return (
    record.recordType === RecordType.FieldInspection ||
    record.recordType === RecordType.OutageScope ||
    record.recordType === RecordType.ValveDiagnostic
  );
}

export function recordHasDetailsField(record: Record) {
  return record.recordType === RecordType.FieldInspection || record.recordType === RecordType.ValveDiagnostic;
}

export const useGetUsedAssetIdsForRecord = (record: {
  recordType: RecordType | null;
  recordsEventId: number | null;
  assets: AssetDigest[];
}) => {
  const getUsedAssetIdsOnOutageScopeRecordsByRecordsEventId =
    record.recordType !== RecordType.OutageScope ? null : record.recordsEventId;
  const { data: usedAssetIds } = useGetUsedAssetIdsOnOutageScopeRecordsByRecordsEventId(
    getUsedAssetIdsOnOutageScopeRecordsByRecordsEventId
  );

  return !!usedAssetIds
    ? usedAssetIds.filter((id) => !record.assets || !_.some(record.assets.filter((ar) => ar.id === id)))
    : [];
};

export const useGetUsedRecordsEventIdsForRecord = (record: {
  recordType: RecordType | null;
  recordsEventId: number | null;
  assets: AssetDigest[];
}) => {
  const getUsedRecordsEventIdsOnOutageScopeRecordsByAssetId =
    record.recordType !== RecordType.OutageScope ? null : _.first(record.assets)?.id ?? null;
  const { data: usedRecordsEventIds } = useGetUsedRecordsEventIdsOnOutageScopeRecordsByAssetId(
    getUsedRecordsEventIdsOnOutageScopeRecordsByAssetId
  );

  return !!usedRecordsEventIds ? usedRecordsEventIds.filter((id) => id !== record.recordsEventId) : [];
};

export const isRecordStatusSettable = (recordType: RecordType) =>
  [
    RecordType.Activity,
    RecordType.CandSU,
    RecordType.Predictive,
    RecordType.Preventative,
    RecordType.Reactive,
  ].includes(recordType);

export const isRecordDescriptionSettable = (recordType: RecordType) =>
  [
    RecordType.Activity,
    RecordType.CandSU,
    RecordType.Predictive,
    RecordType.Preventative,
    RecordType.Proactive,
    RecordType.Reactive,
  ].includes(recordType);

export const isRecordObservationSettable = (recordType: RecordType) =>
  [
    RecordType.Activity,
    RecordType.FieldInspection,
    RecordType.CandSU,
    RecordType.Predictive,
    RecordType.Preventative,
    RecordType.Proactive,
    RecordType.VibrationAnalysis,
    RecordType.Reactive,
    RecordType.EquipmentHealth,
  ].includes(recordType);
