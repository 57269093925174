import * as React from "react";
import { RecordType } from "records";
import { Box, Chip, useTheme } from "@mui/material";

interface Props {
  type?: RecordType;
  isDarkTheme?: boolean;
}

const getRecordTypeBadgeIcon = (type?: RecordType, fill: string = "") => {
  switch (type) {
    case RecordType.Activity:
      return <ActivityIcon fill={fill} />;
    case RecordType.CandSU:
      return <CommissioningIcon fill={fill} />;
    case RecordType.FieldInspection:
      return <FieldInspectionIcon fill={fill} />;
    case RecordType.OutageScope:
      return <OutageScopeIcon fill={fill} />;
    case RecordType.ValveDiagnostic:
      return <ValveDiagnosticIcon fill={fill} />;
    case RecordType.Proactive:
      return <ProactiveIcon fill={fill} />;
    case RecordType.Reactive:
      return <ReactiveIcon fill={fill} />;
    case RecordType.Predictive:
      return <PredictiveIcon fill={fill} />;
    case RecordType.Preventative:
      return <PreventativeIcon fill={fill} />;
    case RecordType.VibrationAnalysis:
      return <VibrationAnalysisIcon fill={fill} />;
    case RecordType.EquipmentHealth:
      return <EquipmentHealthIcon fill={fill} />;
    default:
      return null;
  }
};

export const RecordTypeBadge = (props: Props) => {
  const assetColor = "primary";
  const theme = useTheme();
  const fill = !!props.isDarkTheme ? theme.palette.background.paper : theme.palette.primary.main;

  return (
    <Chip
      size={"small"}
      label={props.type}
      variant="outlined"
      color={assetColor}
      sx={{
        borderRadius: 1,
        borderColor: fill,
        color: fill,
        width: "170px",
        justifyContent: "flex-start",
      }}
      icon={
        <Box
          sx={{
            pl: 0.5,
            width: "16px",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {getRecordTypeBadgeIcon(props.type, fill)}
        </Box>
      }
    />
  );
};

const ActivityIcon = (props: { fill: string }) => {
  return (
    <svg width="10" height="14" viewBox="0 0 10 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.58597 2.48293C7.26257 2.48293 7.82744 1.91186 7.82744 1.24146C7.82744 0.546245 7.26257 0 6.58597 0C5.89696 0 5.34451 0.546245 5.34451 1.24146C5.34451 1.91186 5.89696 2.48293 6.58597 2.48293ZM4.34513 11.0987L4.96586 8.37989L6.2756 9.62135V13.3457H7.51707V8.69025L6.20732 7.44879L6.58597 5.58659C7.38672 6.51769 8.62818 7.13842 10 7.13842V5.89696C8.8144 5.89696 7.82744 5.27623 7.32464 4.39479L6.70391 3.41403C6.45562 3.02917 6.08318 2.7933 5.65487 2.7933C5.46245 2.7933 5.34451 2.84295 5.15208 2.84295L1.93048 4.20857V7.13842H3.17194V5.01552L4.28305 4.58101L3.28988 9.62135L0.248293 9.00062L0 10.2421L4.34513 11.0987Z"
        fill={props.fill}
      />
    </svg>
  );
};

const CommissioningIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.6 1.8V1.2C9.6 0.88174 9.47357 0.576515 9.24853 0.351472C9.02348 0.126428 8.71826 0 8.4 0H7.2C6.88174 0 6.57652 0.126428 6.35147 0.351472C6.12643 0.576515 6 0.88174 6 1.2V1.8H4.8V1.2C4.8 0.88174 4.67357 0.576515 4.44853 0.351472C4.22348 0.126428 3.91826 0 3.6 0H2.4C2.08174 0 1.77652 0.126428 1.55147 0.351472C1.32643 0.576515 1.2 0.88174 1.2 1.2V1.8H0V10.2H6.054C6.01764 10.0021 5.99957 9.80123 6 9.6C5.9997 9.02391 6.13766 8.45616 6.40228 7.94445C6.66691 7.43273 7.05048 6.992 7.52077 6.65927C7.99106 6.32654 8.53433 6.11154 9.10495 6.03231C9.67556 5.95309 10.2569 6.01197 10.8 6.204V1.8M10.2 7.2V9H12V10.2H10.2V12H9V10.2H7.2V9H9V7.2H10.2Z"
        fill={props.fill}
      />
    </svg>
  );
};

const FieldInspectionIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M9.33333 5.33333H2.66667V4H9.33333M9.33333 8H2.66667V6.66667H9.33333M7.33333 10.6667H2.66667V9.33333H7.33333M6 1.33333C6.17681 1.33333 6.34638 1.40357 6.4714 1.5286C6.59643 1.65362 6.66667 1.82319 6.66667 2C6.66667 2.17681 6.59643 2.34638 6.4714 2.4714C6.34638 2.59643 6.17681 2.66667 6 2.66667C5.82319 2.66667 5.65362 2.59643 5.5286 2.4714C5.40357 2.34638 5.33333 2.17681 5.33333 2C5.33333 1.82319 5.40357 1.65362 5.5286 1.5286C5.65362 1.40357 5.82319 1.33333 6 1.33333ZM10.6667 1.33333H7.88C7.6 0.56 6.86667 0 6 0C5.13333 0 4.4 0.56 4.12 1.33333H1.33333C0.979711 1.33333 0.640573 1.47381 0.390524 1.72386C0.140476 1.97391 0 2.31304 0 2.66667V12C0 12.3536 0.140476 12.6928 0.390524 12.9428C0.640573 13.1929 0.979711 13.3333 1.33333 13.3333H10.6667C11.0203 13.3333 11.3594 13.1929 11.6095 12.9428C11.8595 12.6928 12 12.3536 12 12V2.66667C12 2.31304 11.8595 1.97391 11.6095 1.72386C11.3594 1.47381 11.0203 1.33333 10.6667 1.33333Z"
        fill={props.fill}
      />
    </svg>
  );
};

const OutageScopeIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M6.15385 6.52308L9.84615 4.43077V6.76923C10.2769 6.76923 10.7077 6.83077 11.0769 7.01538V3.38462C11.0769 3.13846 10.9538 2.95385 10.7692 2.83077L5.90769 0.123077C5.78462 0.0615385 5.66154 0 5.53846 0C5.41538 0 5.29231 0.0615385 5.16923 0.123077L0.307692 2.83077C0.123077 2.95385 0 3.13846 0 3.38462V8.92308C0 9.16923 0.123077 9.35385 0.307692 9.47692L5.16923 12.1846C5.29231 12.2462 5.41538 12.3077 5.53846 12.3077C5.66154 12.3077 5.78462 12.2462 5.90769 12.1846L6.46154 11.8769C6.27692 11.5077 6.21538 11.0769 6.15385 10.6462M5.53846 1.35385L9.23077 3.38462L8 4.06154L4.36923 1.96923L5.53846 1.35385ZM4.92308 10.6462L1.23077 8.55385V4.43077L4.92308 6.52308V10.6462ZM5.53846 5.41538L1.84615 3.38462L3.07692 2.64615L6.76923 4.8L5.53846 5.41538ZM8.55385 8.30769L9.84615 9.6L11.1385 8.30769L12 9.16923L10.7077 10.4615L12 11.7538L11.1385 12.6154L9.84615 11.3231L8.55385 12.6154L7.69231 11.7538L8.98462 10.4615L7.69231 9.16923L8.55385 8.30769Z"
        fill={props.fill}
      />
    </svg>
  );
};
const ValveDiagnosticIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="11" viewBox="0 0 12 11" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M8.4 5.268L10.944 0.87L11.982 1.47L8.844 6.9L4.938 4.65L2.076 9.6H12V10.8H0V0H1.2V8.724L4.5 3L8.4 5.268Z"
        fill={props.fill}
      />
    </svg>
  );
};
const ProactiveIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.314 7.314L2.4 9.6L4.686 4.686L9.6 2.4M6 0C5.21207 0 4.43185 0.155195 3.7039 0.456723C2.97595 0.758251 2.31451 1.20021 1.75736 1.75736C0.632141 2.88258 0 4.4087 0 6C0 7.5913 0.632141 9.11742 1.75736 10.2426C2.31451 10.7998 2.97595 11.2417 3.7039 11.5433C4.43185 11.8448 5.21207 12 6 12C7.5913 12 9.11742 11.3679 10.2426 10.2426C11.3679 9.11742 12 7.5913 12 6C12 5.21207 11.8448 4.43185 11.5433 3.7039C11.2417 2.97595 10.7998 2.31451 10.2426 1.75736C9.68549 1.20021 9.02405 0.758251 8.2961 0.456723C7.56815 0.155195 6.78793 0 6 0ZM6 5.34C5.82496 5.34 5.65708 5.40954 5.53331 5.53331C5.40954 5.65708 5.34 5.82496 5.34 6C5.34 6.17504 5.40954 6.34292 5.53331 6.46669C5.65708 6.59047 5.82496 6.66 6 6.66C6.17504 6.66 6.34292 6.59047 6.46669 6.46669C6.59047 6.34292 6.66 6.17504 6.66 6C6.66 5.82496 6.59047 5.65708 6.46669 5.53331C6.34292 5.40954 6.17504 5.34 6 5.34Z"
        fill={props.fill}
      />
    </svg>
  );
};
const ReactiveIcon = (props: { fill: string }) => {
  return (
    <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M11.8255 10.9731L10.9736 11.8251C10.8612 11.9368 10.7091 11.9995 10.5506 11.9995C10.3921 11.9995 10.2401 11.9368 10.1277 11.8251L3.00024 4.70963C2.80514 4.76615 2.60338 4.79641 2.40029 4.79962C2.0183 4.79935 1.64189 4.70789 1.30237 4.53286C0.96284 4.35783 0.670001 4.10427 0.448197 3.79328C0.226393 3.48228 0.0820326 3.12283 0.0271173 2.74481C-0.0277979 2.36679 0.00831894 1.98112 0.132465 1.61987L1.65634 3.14375L1.97432 2.82578L2.82625 1.97384L3.14423 1.65587L1.62035 0.13199C1.9816 0.00784334 2.36727 -0.0282735 2.74529 0.0266417C3.12331 0.081557 3.48276 0.225918 3.79375 0.447722C4.10475 0.669526 4.3583 0.962364 4.53333 1.30189C4.70837 1.64142 4.79982 2.01782 4.80009 2.39981C4.79689 2.6029 4.76662 2.80466 4.7101 2.99976L11.8255 10.1272C11.9373 10.2396 12 10.3917 12 10.5502C12 10.7087 11.9373 10.8607 11.8255 10.9731ZM0.174462 10.1272C0.0627201 10.2396 0 10.3917 0 10.5502C0 10.7087 0.0627201 10.8607 0.174462 10.9731L1.02639 11.8251C1.1388 11.9368 1.29086 11.9995 1.44936 11.9995C1.60786 11.9995 1.75992 11.9368 1.87233 11.8251L5.15407 8.54932L3.4562 6.85146M10.7996 0L8.39981 1.1999V2.39981L7.09791 3.70171L8.29782 4.90161L9.59971 3.59971H10.7996L11.9995 1.1999L10.7996 0Z"
        fill={props.fill}
      />
    </svg>
  );
};
const PreventativeIcon = (props: { fill: string }) => {
  return (
    <svg width="11" height="14" viewBox="0 0 11 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M5.5 0L0 2.44444V6.11111C0 9.50278 2.34667 12.6744 5.5 13.4444C8.65333 12.6744 11 9.50278 11 6.11111V2.44444L5.5 0ZM7.38222 9.16667L5.5 8.03611L3.62389 9.16667L4.11889 7.02778L2.46278 5.59778L4.65056 5.40833L5.5 3.39167L6.34944 5.40222L8.53722 5.59167L6.88111 7.02778L7.38222 9.16667Z"
        fill={props.fill}
      />
    </svg>
  );
};
const PredictiveIcon = (props: { fill: string }) => {
  return (
    <svg width="10" height="12" viewBox="0 0 10 12" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M3.26061 3.56364L4.54545 4.14545L5.8303 3.56364L5.24849 4.84848L5.8303 6.13333L4.54545 5.55152L3.26061 6.13333L3.84242 4.84848L3.26061 3.56364ZM7.57576 0L8.23636 1.76364L10 2.42424L8.23636 3.08485L7.57576 4.84848L6.91515 3.08485L5.15152 2.42424L6.91515 1.76364L7.57576 0ZM1.21212 10H1.81818V9.39394C1.81818 9.2332 1.88203 9.07905 1.99569 8.96539C2.10935 8.85173 2.26351 8.78788 2.42424 8.78788H2.58182C1.21212 8 0.30303 6.53333 0.30303 4.84848C0.30303 3.64296 0.781925 2.4868 1.63436 1.63436C2.4868 0.781925 3.64296 0.30303 4.84848 0.30303C5.48485 0.30303 6.09091 0.436364 6.64242 0.672727L6.41818 1.26667L5.55758 1.58788C5.32727 1.53939 5.09091 1.51515 4.84848 1.51515C3.96443 1.51515 3.11658 1.86634 2.49146 2.49146C1.86634 3.11658 1.51515 3.96443 1.51515 4.84848C1.51515 5.73254 1.86634 6.58039 2.49146 7.20551C3.11658 7.83063 3.96443 8.18182 4.84848 8.18182C6.61212 8.18182 8.06061 6.80606 8.18182 5.07273L8.73333 3.58182L9.16364 3.41818C9.31515 3.86667 9.39394 4.35152 9.39394 4.84848C9.39394 6.53333 8.48485 8 7.11515 8.78788H7.27273C7.43346 8.78788 7.58762 8.85173 7.70128 8.96539C7.81494 9.07905 7.87879 9.2332 7.87879 9.39394V10H8.48485C8.80632 10 9.11463 10.1277 9.34195 10.355C9.56926 10.5823 9.69697 10.8906 9.69697 11.2121V11.8182H0V11.2121C0 10.8906 0.127705 10.5823 0.355022 10.355C0.582339 10.1277 0.890647 10 1.21212 10Z"
        fill={props.fill}
      />
    </svg>
  );
};

const VibrationAnalysisIcon = (props: { fill: string }) => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M9 22C8.45 22 7.97933 21.8043 7.588 21.413C7.19667 21.0217 7.00067 20.5507 7 20V17H4C3.45 17 2.97933 16.8043 2.588 16.413C2.19667 16.0217 2.00067 15.5507 2 15V13H8.45L10.15 15.55C10.2333 15.6833 10.35 15.7917 10.5 15.875C10.65 15.9583 10.8083 16 10.975 16C11.1917 16 11.3917 15.9333 11.575 15.8C11.7583 15.6667 11.8833 15.5 11.95 15.3L13.3 11.25L14.15 12.55C14.25 12.6833 14.375 12.7917 14.525 12.875C14.675 12.9583 14.8333 13 15 13H22V15C22 15.55 21.804 16.021 21.412 16.413C21.02 16.805 20.5493 17.0007 20 17H17V20C17 20.55 16.8043 21.021 16.413 21.413C16.0217 21.805 15.5507 22.0007 15 22H9ZM10.7 12.75L9.825 11.45C9.74167 11.3167 9.625 11.2083 9.475 11.125C9.325 11.0417 9.16667 11 9 11H2V9C2 8.45 2.196 7.97933 2.588 7.588C2.98 7.19667 3.45067 7.00067 4 7H7V4C7 3.45 7.196 2.97933 7.588 2.588C7.98 2.19667 8.45067 2.00067 9 2H15C15.55 2 16.021 2.196 16.413 2.588C16.805 2.98 17.0007 3.45067 17 4V7H20C20.55 7 21.021 7.196 21.413 7.588C21.805 7.98 22.0007 8.45067 22 9V11H15.525L13.825 8.45C13.7417 8.31667 13.625 8.20833 13.475 8.125C13.325 8.04167 13.1667 8 13 8C12.7833 8 12.5877 8.06667 12.413 8.2C12.2383 8.33333 12.1173 8.5 12.05 8.7L10.7 12.75Z"
        fill={props.fill} />
    </svg>
  );
};

const EquipmentHealthIcon = (props: { fill: string }) => {
  return (
    <svg width="11" height="13" viewBox="0 0 11 13" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.5625 8.6875H6.4375V7.125H8V5.25H6.4375V3.6875H4.5625V5.25H3V7.125H4.5625V8.6875ZM5.5 12.75C4.05208 12.3854 2.85677 11.5547 1.91406 10.2578C0.971354 8.96094 0.5 7.52083 0.5 5.9375V2.125L5.5 0.25L10.5 2.125V5.9375C10.5 7.52083 10.0286 8.96094 9.08594 10.2578C8.14323 11.5547 6.94792 12.3854 5.5 12.75Z"
        fill={props.fill} />
    </svg>
  );
};
