export enum HealthCategoryType {
  Good = "Good",
  Minor = "Minor",
  Moderate = "Moderate",
  Severe = "Severe",
}

export type HealthCategory = "Good" | "Minor" | "Moderate" | "Severe" | "N/A";

export interface Health {
  asLeftCondition: number | null;
  asLeftConditionCategory: HealthCategory | null;
  asLeftPerformance: number | null;
  asLeftPerformanceCategory: HealthCategory | null;
  asLeftOverall: number | null;
  asLeftOverallCategory: HealthCategory | null;
  asFoundCondition: number | null;
  asFoundConditionCategory: HealthCategory | null;
  asFoundPerformance: number | null;
  asFoundPerformanceCategory: HealthCategory | null;
  asFoundOverall: number | null;
  asFoundOverallCategory: HealthCategory | null;
  overall: number | null;
  overallCategory: HealthCategory | null;
  condition: number | null;
  conditionCategory: HealthCategory | null;
  performance: number | null;
  performanceCategory: HealthCategory | null;
  isAsLeftManuallyChanged: boolean;
}

export enum HealthFieldLabel {
  Overall = "Overall health",
  Condition = "Condition",
  Performance = "Performance",
  AsFoundOverall = "Overall health (as found)",
  AsFoundCondition = "Condition (as found)",
  AsFoundPerformance = "Performance (as found)",
  AsLeftOverall = "Overall health (as left)",
  AsLeftCondition = "Condition (as left)",
  AsLeftPerformance = "Performance (as left)",
}
