import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { useGetTemplateRecommendationsRequest } from "common/api";
import { uniq, uniqBy, sortBy, orderBy, flatten } from "lodash";
import { useEffect, useState } from "react";
import { useCreateRecommendationRequest } from "recommendations/api";
import { RecordDetailView, TemplateRecommendation } from "records/models";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { RecordStatusLoader } from "app/mui/RecordStatusLoader";

interface AddRecommendationDialogProps extends BaseDialogProps {
  record?: RecordDetailView;
  packagedSolutionId?: number;
}

interface RecommendationTemplateOption {
  value: string;
  label: string;
  category: string;
}

const validationSchema = yup.object({
  selectedTemplateRecommendation: yup.object().nullable().required("Required"),
  recommendationTitle: yup.string().required("required"),
  recommendationContent: yup.string().nullable(),
  recommendationStatus: yup.string().required("required"),
});

export const AddRecommendationDialog = (props: AddRecommendationDialogProps) => {
  const { record, packagedSolutionId, onClose } = props;
  const createRecommendationRequest = useCreateRecommendationRequest();

  const methods = useForm({ resolver: yupResolver(validationSchema) });
  const selectedTemplateRecommendation = methods.watch("selectedTemplateRecommendation");

  const getTemplateRecommendationsQuery = useGetTemplateRecommendationsRequest();
  const allTemplateRecommendations = getTemplateRecommendationsQuery.data ?? [];

  const [templateRecommendations, setTemplateRecommendations] = useState<TemplateRecommendation[]>([]);
  const [templateRecommendationCategories, setTemplateRecommendationCategories] = useState<string[]>([]);
  // force re-render when selectedTemplateRecommendation is changed
  const [richTextEditorKey, setRichTextEditorKey] = useState<number>();
  const blankRecommendation = {
    value: "0",
    label: "Blank recommendation",
    category: "",
  } as RecommendationTemplateOption;

  useEffect(() => {
    if (getTemplateRecommendationsQuery.loading) {
      return;
    }

    const recordAssetTypes = uniq(record?.model.assets.map((ar) => ar.assetType));
    const filteredTemplateRecomendations = allTemplateRecommendations.filter(
      (atr) =>
        atr.recordType?.toLowerCase() === record?.model.recordType.toLowerCase() &&
        recordAssetTypes.some((rat) => atr.assetTypes.includes(rat))
    );

    const uniqueCategories = sortBy(
      uniqBy(filteredTemplateRecomendations, (rec) => rec.category).map((rec) => rec.category),
      (c) => c
    );

    if (uniqueCategories.length) {
      setTemplateRecommendations(uniqBy(filteredTemplateRecomendations, (rec) => rec.id));
      setTemplateRecommendationCategories(uniqueCategories);
    }

    methods.setValue("selectedTemplateRecommendation", blankRecommendation);
  }, [getTemplateRecommendationsQuery.loading]);

  useEffect(() => {
    const templateRecommendation = templateRecommendations.find(
      (t) => t.id === parseInt(selectedTemplateRecommendation?.value)
    );
    methods.setValue("recommendationTitle", templateRecommendation?.title);
    methods.setValue("recommendationContent", templateRecommendation?.content);
    setRichTextEditorKey(templateRecommendation?.id);
  }, [selectedTemplateRecommendation]);

  const templateCategories = orderBy(templateRecommendationCategories, (trc) => trc);

  const templateRecommendationOptions =
    templateCategories !== null
      ? [blankRecommendation].concat(
          flatten(
            templateCategories.map((tc) => {
              return orderBy(
                (templateRecommendations || []).filter((tr) => tr.category === tc),
                (rec) => rec.title
              ).map(
                (templateRec) =>
                  ({
                    value: templateRec.id.toString(),
                    label: templateRec.title,
                    category: tc,
                  } as RecommendationTemplateOption)
              );
            })
          )
        )
      : [];

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      createRecommendationRequest
        .call({
          recordId: record?.id,
          packagedSolutionId: packagedSolutionId,
          title: values.recommendationTitle,
          status: values.recommendationStatus,
          content: values.recommendationContent,
        })
        .then(() => {
          onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Add Recommendation
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <AutocompleteFormItem
              options={templateRecommendationOptions}
              getOptionLabel={(o) => o.label}
              autocompleteProps={{
                disablePortal: false,
                isOptionEqualToValue: (option, value) => option.value === value.value,
                groupBy: (option) => option.category,
              }}
              label="Template Name"
              fieldName="selectedTemplateRecommendation"
              idSelector={(template) => template}
            />
            <TextFieldFormItem label="Title" fieldName="recommendationTitle" />
            <RichTextEditor key={richTextEditorKey} fieldName="recommendationContent" />
            <RecordStatusLoader>
              {(options) => <AutocompleteFormItem label="Status" fieldName="recommendationStatus" {...options} />}
            </RecordStatusLoader>
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Add
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
