import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { checkAssetType } from "assets/utilities";
import { AccessorySpec } from "assets/models/asset";
import { EditableYesNoFormItem } from "app/mui/forms/YesNoForItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateAccessorySpecificationRequest } from "assets/api";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface AccessorySpecificationCardProps {
  assetType: AssetType;
  accessory: AccessorySpec;
  assetSpec?: AccessorySpec;
  recordId?: number;
}

export const AccessoriesSpecificationCard = (props: AccessorySpecificationCardProps) => {
  const { assetType, accessory, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdateAccessorySpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(accessory);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          accessory: values,
        });
      })();
    }
  };

  const { isAutomatedValve, isManualValve, isMotorOperatedValve } = checkAssetType(assetType);

  const automatedValveFields = isAutomatedValve
    ? [
        <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
        isMotorOperatedValve && <EditableTextFieldFormItem label="Gearbox" fieldName="gearBox" />,
        <EditableTextFieldFormItem label="Volume booster" fieldName="volumeBooster" />,
        <EditableTextFieldFormItem label="Quick release" fieldName="quickRelease" />,
        <EditableTextFieldFormItem label="Solenoid valve" fieldName="solenoidValve" />,
        <EditableTextFieldFormItem label="Instrument regulator" fieldName="instrumentRegulator" />,
        <EditableTextFieldFormItem label="Pressure switch" fieldName="pressureSwitch" />,
        <EditableTextFieldFormItem label="Position transmitter" fieldName="positionTransmitter" />,
        <EditableTextFieldFormItem label="Limit switch" fieldName="limitSwitch" />,
        <EditableYesNoFormItem label="Handwheel" fieldName="handwheel" />,
      ]
    : [];

  const manualValveFields = isManualValve
    ? [
        <EditableTextFieldFormItem label="Position transmitter" fieldName="positionTransmitter" />,
        <EditableTextFieldFormItem label="Limit switch" fieldName="limitSwitch" />,
        <EditableYesNoFormItem label="Handwheel" fieldName="handwheel" />,
      ]
    : [];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Accessories"
            specs={[...automatedValveFields, ...manualValveFields]}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(accessory)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard title="Accessories" specs={[...automatedValveFields, ...manualValveFields]} />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
