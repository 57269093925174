import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { MeasurementSpec } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import AutoSave from "app/mui/forms/AutoSave";
import { useUpdateMeasurementSpecificationRequest } from "assets/api";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface MeasurementSpecificationCardProps {
  assetType: AssetType;
  measurement: MeasurementSpec;
  assetSpec?: MeasurementSpec;
  recordId?: number;
}

export const MeasurementSpecificationCard = (props: MeasurementSpecificationCardProps) => {
  const { assetType, measurement, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdateMeasurementSpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(measurement);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          measurement: values,
        });
      })();
    }
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <SpecOptionLoader type="MeasurementType">
      {(options) => <EditableAutocompleteFormItem label="Type" fieldName="type" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="Technology" fieldName="technology" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <EditableTextFieldFormItem label="Emission Vent ID" fieldName="ventId" />,
  ];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Measurement"
            specs={fields}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(measurement)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard title="Measurement" specs={fields} />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
