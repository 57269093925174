import { Box, LinearProgress } from "@mui/material";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { useGetPackagedSolutionEquipmentsRequest } from "packagedSolution/api";
import { useGetRecordsByAssetQuery } from "records";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { CustomToolbar } from "app/mui/tables/CustomToolbar";
import { getColumnDef, getColumnVisibilityModel } from "app/mui/tables/utils/defaultColumns";

interface PackagedSolutionRecordTabProps {
  packagedSolutionId: number;
}

const defaultColumns = [
  "link",
  "model.recordType",
  "model.status",
  "model.assets",
  "createdBy",
  "created",
  "model.eventDate",
  "model.followUp",
];

export const PackagedSolutionRecordTab = (props: PackagedSolutionRecordTabProps) => {
  const equipmentsRequest = useGetPackagedSolutionEquipmentsRequest(props.packagedSolutionId);
  const equipments = equipmentsRequest.data ?? [];

  const assetRecordsRequest = useGetRecordsByAssetQuery(equipments.flatMap((e) => e.assets.map((a) => a.id)));
  const apiRef = useGridApiRef();

  return (
    <Box sx={{ height: "300px" }}>
      <Box sx={{ height: "100%", width: "100%" }}>
        <MetadataColumnProvider type="RecordDetailViewModel">
          {(columns) => (
            <StripedDataGrid
              apiRef={apiRef}
              density="compact"
              loading={assetRecordsRequest.loading}
              columns={getColumnDef(columns, defaultColumns)}
              columnVisibilityModel={getColumnVisibilityModel(columns, defaultColumns)}
              rows={assetRecordsRequest.data ?? []}
              components={{
                LoadingOverlay: LinearProgress,
                Toolbar: CustomToolbar,
              }}
            />
          )}
        </MetadataColumnProvider>
      </Box>
    </Box>
  );
};
