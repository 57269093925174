import { EquipmentHealthReportCardModel } from "@bluemarvel/iris-common/model";
import { Paper, Stack, Typography } from "@mui/material";
import { HealthBadge } from "assets/mui/health/HealthBadge";
import { TrafficLight } from "assets/mui/health/TrafficLight";
import { getTrafficLightOption } from "records/models";

interface EquipmentHealthCardProps {
  reportCard: EquipmentHealthReportCardModel;
}

export const EquipmentHealthCard = (props: EquipmentHealthCardProps) => {
  const { reportCard } = props;
  const asFoundScore = Math.max(
    reportCard.asFound_accuracyCalibration,
    reportCard.asFound_dataIntegrityMaintenance,
    reportCard.asFound_measurementSystemIntegrity,
    reportCard.asFound_reportingAuditing
  );
  const asFoundHealth = getTrafficLightOption(asFoundScore).label;
  const asLeftScore = Math.max(
    reportCard.asLeft_accuracyCalibration,
    reportCard.asLeft_dataIntegrityMaintenance,
    reportCard.asLeft_measurementSystemIntegrity,
    reportCard.asLeft_reportingAuditing
  );
  const asLeftHealth = getTrafficLightOption(asLeftScore).label;

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Typography variant="h6">EQUIPMENT HEALTH</Typography>
      <Stack
        direction="row"
        sx={{
          p: 2,
        }}
      >
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="subtitle2">As Found</Typography>
          <TrafficLight score={asFoundScore} />
          <HealthBadge health={asFoundHealth} />
        </Stack>
        <Stack
          alignItems="center"
          spacing={2}
          sx={{
            width: "100%",
          }}
        >
          <Typography variant="subtitle2">As Left</Typography>
          <TrafficLight score={asLeftScore} />
          <HealthBadge health={asLeftHealth} />
        </Stack>
      </Stack>
    </Paper>
  );
};
