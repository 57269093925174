import { EquipmentType } from "@bluemarvel/iris-common/model";
import { Stack, Typography } from "@mui/material";
import FormItemLabel from "app/mui/forms/FormItemLabel";

interface EquipmentTypeProps {
  equipmentType: EquipmentType | null;
  equipmentTypeDescription: string | null;
}

export const EquipmentLabel = ({ equipmentType, equipmentTypeDescription }: EquipmentTypeProps) => {
  console.log(equipmentType);
  const equipmentLabel =
    equipmentType === "Other" && equipmentTypeDescription
      ? `${equipmentType} - ${equipmentTypeDescription}`
      : equipmentType;
  return (
    <Stack direction="column" spacing={1}>
      <FormItemLabel label="Equipment Type" />
      <Stack>
        <Typography variant="body2" color={"primary"}>
          {equipmentLabel}
        </Typography>
      </Stack>
    </Stack>
  );
};
