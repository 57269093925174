import { GridColDef, GridColumns } from "@mui/x-data-grid-pro";

export const getColumnVisibilityModel = (columns: GridColDef[], fields: string[]) =>
  Object.fromEntries(columns.map((col) => (fields.includes(col.field) ? [col.field, true] : [col.field, false])));

export const getColumnDef = (columns: GridColDef[], fields: string[]) => {
  const gridColumns: GridColumns = [];
  for (const field of fields) {
    const def = columns.find((x) => x.field === field);
    if (def) {
      gridColumns.push({ ...def, flex: 1 });
    }
  }
  const others = columns.filter((x) => !fields.includes(x.field));
  return gridColumns.concat(others);
};
