import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useUpdatePilotSpecificationRequest } from "assets/api";
import { PilotSpec } from "assets/models/asset";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface PilotSpecificationCardProps {
  assetType: AssetType;
  pilot: PilotSpec;
  assetSpec?: PilotSpec;
  recordId?: number;
}

export const PilotSpecificationCard = (props: PilotSpecificationCardProps) => {
  const { assetType, pilot, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdatePilotSpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(pilot);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          pilot: values,
        });
      })();
    }
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Pilot size" fieldName="size" />,
    <EditableTextFieldFormItem label="Spring range" fieldName="springRange" />,
  ];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Pilot"
            specs={fields}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(pilot)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard title="Pilot" specs={fields} />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
