import * as React from "react";
import { Box, Grid, Paper } from "@mui/material";
import AddBoxOutlinedIcon from "@mui/icons-material/AddBoxOutlined";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { ValveSpecificationCard } from "./specifications/Valve";
import { ActuatorSpecificationCard } from "./specifications/Actuator";
import { AssetDeviceSpecificationCard } from "./specifications/AssetDevice";
import { AccessoriesSpecificationCard } from "./specifications/Accessories";
import { MachinerySpecificationCard } from "./specifications/Machinery";
import { ElementSpecificationCard } from "./specifications/Element";
import { PilotSpecificationCard } from "./specifications/Pilot";
import { RegulatorSpecificationCard } from "./specifications/Regulator";
import { MachineryComponentSpecificationCard } from "./specifications/MachineryComponent";
import { useCreateAssetDeviceRequest } from "assets/api";
import { checkAssetType } from "assets/utilities";
import { MeasurementSpecificationCard } from "./specifications/Measurement";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { SteamTrapSpecificationCard } from "./specifications/SteamTrap";
import { TankSpecificationCard } from "./specifications/Tank";
import { Asset } from "assets/models/asset";
import { AssetDetailView } from "assets/models/assetDetail";
import { AssetDevice } from "assets/models/assetDevice";
import { AssetType } from "assets/models/assetType";
import { GeneralSpecificationCard } from "./specifications/General";

interface AssetSpecificationsProps {
  assetDetail: AssetDetailView;
}

const pageActions = (asset: Asset, isLoading: boolean, numberOfDevices: number) =>
  createStaticActions((actionDispatcher, userPermissions) => [
    {
      icon: <AddBoxOutlinedIcon />,
      label: "Add new device",
      disabled: isLoading || numberOfDevices >= 3,
      hidden: !userPermissions.userCanEditAsset(asset.assetType),
      action: () => {
        actionDispatcher.dispatch("AddDevice");
      },
    },
  ]);

export const AssetSpecifications = (props: AssetSpecificationsProps) => {
  const { assetDetail } = props;
  const asset = assetDetail.model;

  const assetDevices = assetDetail.assetDevices ?? [];

  const {
    isValve,
    isAutomatedValve,
    isManualValve,
    isReliefValve,
    isRegulator,
    isMeasurement,
    isMachinery,
    isSteamTrap,
    isTank,
    isGeneral,
  } = checkAssetType(asset.assetType);

  const createDeviceRequest = useCreateAssetDeviceRequest(asset.id);

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(
    pageActions(asset, createDeviceRequest.loading, assetDevices.length),
    actionDispatcher
  );

  const handleAction = React.useCallback((action: string) => {
    if (action === "AddDevice") {
      addNewDevice();
    }
  }, []);

  React.useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const addNewDevice = () => {
    createDeviceRequest.call({ id: 0, assetId: asset.id });
  };

  const allowedAssetTypesForDeviceCreation = [AssetType.ControlValve, AssetType.IsolationValve];
  const displayActionsDropdownMenu = allowedAssetTypesForDeviceCreation.includes(asset.assetType);

  return (
    <Paper>
      <ExpansionPanel
        title="Specifications"
        rightSideComponent={displayActionsDropdownMenu && <ActionsDropdownMenu actions={actions} />}
      >
        <Box sx={{ p: 2 }}>
          <Grid container spacing={2}>
            {isValve && <ValveSpecificationCard assetType={asset.assetType} valve={assetDetail.valveSpecification} />}

            {isAutomatedValve && (
              <ActuatorSpecificationCard assetType={asset.assetType} actuator={assetDetail.actuatorSpecification} />
            )}

            {isAutomatedValve && assetDevices.length === 0 && (
              <AssetDeviceSpecificationCard key={0} asset={asset} device={{ id: 0 } as AssetDevice} deviceNumber={1} />
            )}

            {isAutomatedValve &&
              assetDevices.map((assetDevice, i) => (
                <AssetDeviceSpecificationCard
                  key={assetDevice.id}
                  asset={asset}
                  device={assetDevice}
                  deviceNumber={i + 1}
                />
              ))}

            {(isAutomatedValve || isManualValve) && (
              <AccessoriesSpecificationCard assetType={asset.assetType} accessory={assetDetail.accessorySpecification} />
            )}

            {isRegulator && <RegulatorSpecificationCard assetType={asset.assetType} regulator={assetDetail.regulatorSpecification} />}
            {(isReliefValve || isRegulator) && (
              <PilotSpecificationCard assetType={asset.assetType} pilot={assetDetail.pilotSpecification} />
            )}

            {isMeasurement && (
              <MeasurementSpecificationCard assetType={asset.assetType} measurement={assetDetail.measurementSpecification} />
            )}
            {isMeasurement && <ElementSpecificationCard assetType={asset.assetType} element={assetDetail.elementSpecification} />}

            {isMachinery && <MachineryComponentSpecificationCard componentNumber={1} fieldPrefix="component1" />}
            {isMachinery && <MachineryComponentSpecificationCard componentNumber={2} fieldPrefix="component2" />}
            {isMachinery && <MachineryComponentSpecificationCard componentNumber={3} fieldPrefix="component3" />}
            {isMachinery && <MachinerySpecificationCard />}
            {isSteamTrap && <SteamTrapSpecificationCard assetType={asset.assetType} steamTrap={assetDetail.steamTrapSpecification} />}
            {isTank && <TankSpecificationCard assetType={asset.assetType} tank={assetDetail.tankSpecification} />}
            {isGeneral && <GeneralSpecificationCard asset={asset} general={assetDetail.generalSpecification} />}
          </Grid>
        </Box>
      </ExpansionPanel>
    </Paper>
  );
};
