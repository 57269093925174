import { Button, DialogActions, DialogContent, DialogTitle, MenuItem, Stack, Typography } from "@mui/material";
import { RecordDetailView, RecordType } from "records/models";
import { FormProvider, useForm, useWatch } from "react-hook-form";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import SplitButton from "app/mui/SplitButton";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { useSiteContext } from "app";
import { useDuplicateRecordRequest } from "records/api";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";
import { useGetUsedAssetIdsForRecord, useGetUsedRecordsEventIdsForRecord } from "records/utilities";
import { EventsLoader } from "app/mui/loaders/EventsLoader";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";

interface DuplicateRecordDialogProps extends BaseDialogProps {
  record: RecordDetailView;
  onComplete?: (record: RecordDetailView) => void;
}

const validationSchema = yup.object({
  //newTag: yup.string().nullable().required("required"),
});

export const DuplicateRecordDialog = (props: DuplicateRecordDialogProps) => {
  const { currentSiteWithNavigation } = useSiteContext();

  const duplicateRecordRequest = useDuplicateRecordRequest();

  const getDefaultValues = () => {
    const isOutage = props.record.model.recordType === RecordType.OutageScope;

    return {
      record: {
        eventDate: props.record.model.eventDate,
        recordsEventId: props.record.model.recordsEventId,
        description: props.record.model.description,
        assets: isOutage ? [] : props.record.model.assets,
      },
      options: {
        duplicateKpis: false,
        duplicateBudgetaryReplacementCost: isOutage,
        duplicateOriginalScopeOfWork: isOutage,
        duplicateScopeRecommendations: isOutage,
      },
    };
  };

  const methods = useForm({
    defaultValues: getDefaultValues(),
    resolver: yupResolver(validationSchema),
  });

  const onSubmit = (options?: { navigate?: boolean }) => {
    methods.handleSubmit((values) => {
      duplicateRecordRequest
        .call({
          record: {
            ...props.record.model,
            ...values.record,
          },
          ...values.options,
        })
        .then((newRecord) => {
          if (options?.navigate) {
            window.open(currentSiteWithNavigation!.routeTo.Record({ id: newRecord.id }));
          }
          props.onComplete?.(newRecord);
          props.onClose();
        });
    })();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        <Typography variant="h6">Duplicate record</Typography>
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <DuplicateRecordForm recordType={props.record.model.recordType} />
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained" }}
            primaryButton={<Button onClick={() => onSubmit({ navigate: true })}>Duplicate and Open</Button>}
            additionalActions={[<MenuItem onClick={() => onSubmit()}>Duplicate and exit</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};

export const DuplicateRecordForm = (props: { recordType: RecordType }) => {
  const recordsEventId = useWatch({ name: "record.recordsEventId" });
  const assets = useWatch({ name: "record.assets" });

  const isValveDiagnostic = props.recordType === RecordType.ValveDiagnostic;
  const isOutageScope = props.recordType === RecordType.OutageScope;
  const isFieldInspection = props.recordType === RecordType.FieldInspection;
  const isVibrationAnalysis = props.recordType === RecordType.VibrationAnalysis;

  const showDescription = !isFieldInspection && !isOutageScope && !isValveDiagnostic && !isVibrationAnalysis;
  const showRecordsEventId = isOutageScope;

  const disabledAssetIds = useGetUsedAssetIdsForRecord({
    assets: [],
    recordsEventId,
    recordType: props.recordType,
  });

  const disabledRecordsEventIds = useGetUsedRecordsEventIdsForRecord({
    assets,
    recordsEventId: null,
    recordType: props.recordType,
  });

  return (
    <Stack spacing={1}>
      <MultiAssetSelectFormItem
        label="Asset(s)"
        fieldName="record.assets"
        selectedAssets={isOutageScope ? assets : []}
        disabledAssetIds={disabledAssetIds}
        automatedValvesOnly={isValveDiagnostic}
      />

      {showDescription && <TextFieldFormItem label="Description" fieldName="record.description" />}

      {showRecordsEventId ? (
        <EventsLoader disabledRecordsEventIds={disabledRecordsEventIds}>
          {(options) => <AutocompleteFormItem label="Event" fieldName="record.recordsEventId" {...options} />}
        </EventsLoader>
      ) : (
        <DateInputFormItem label="Occurrence date" fieldName="record.eventDate" />
      )}
    </Stack>
  );
};
