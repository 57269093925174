import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { ActuatorSpec } from "assets/models/asset";
import { checkAssetType } from "assets/utilities";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateActuatorSpecificationRequest } from "assets/api";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface ActuatorSpecificationCardProps {
  assetType: AssetType;
  actuator: ActuatorSpec;
  assetSpec?: ActuatorSpec;
  recordId?: number;
}

export const ActuatorSpecificationCard = (props: ActuatorSpecificationCardProps) => {
  const { assetType, actuator, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdateActuatorSpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(actuator);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          actuator: values,
        });
      })();
    }
  };

  const { isControlValve, isIsolationValve, isMotorOperatedValve } = checkAssetType(assetType);

  const standardFields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <EditableTextFieldFormItem label="Size" fieldName="size" />,
  ];

  const commonFieldsForIsolationAndControlValve =
    isControlValve || isIsolationValve
      ? [
          <EditableTextFieldFormItem label="Lower bench set" fieldName="lowerBenchSet" />,
          <EditableTextFieldFormItem label="Upper bench set" fieldName="upperBenchSet" />,
          <EditableTextFieldFormItem label="Nominal supply pressure" fieldName="nominalSupplyPressure" />,
          <EditableTextFieldFormItem label="Stroke time" fieldName="strokeTime" />,
        ]
      : [];

  const movFields = isMotorOperatedValve
    ? [
        <EditableTextFieldFormItem label="Order/PO" fieldName="order" />,
        <EditableTextFieldFormItem label="Torque/thrust" fieldName="torque" />,
        <EditableTextFieldFormItem label="Speed rating" fieldName="speedRating" />,
        <EditableTextFieldFormItem label="Power supply" fieldName="powerSupply" />,
        <EditableTextFieldFormItem label="Temperature range" fieldName="temperatureRange" />,
        <EditableTextFieldFormItem label="Motor Current" fieldName="motorCurrent" />,
        <EditableTextFieldFormItem label="Voltage rating" fieldName="voltage" />,
        <EditableTextFieldFormItem label="Duty Cycle" fieldName="dutyCycle" />,
      ]
    : [];

  const controlValveFields = isControlValve
    ? [
        <SpecOptionLoader type="ActuatorAir">
          {(options) => <EditableAutocompleteFormItem label="Air" fieldName="air" {...options} />}
        </SpecOptionLoader>,
      ]
    : [];

  const isolationValveFields = isIsolationValve
    ? [
        <SpecOptionLoader type="ActuatorFailAction">
          {(options) => <EditableAutocompleteFormItem label="Fail Action" fieldName="failAction" {...options} />}
        </SpecOptionLoader>,

        <EditableTextFieldFormItem label="Voltage" fieldName="voltage" />,
        <SpecOptionLoader type="ActuatorPhase">
          {(options) => <EditableAutocompleteFormItem label="Phase" fieldName="phase" {...options} />}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Torque" fieldName="torque" />,
      ]
    : [];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Actuator"
            specs={[
              ...standardFields,
              ...commonFieldsForIsolationAndControlValve,
              ...movFields,
              ...controlValveFields,
              ...isolationValveFields,
            ]}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(actuator)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard
            title="Actuator"
            specs={[
              ...standardFields,
              ...commonFieldsForIsolationAndControlValve,
              ...movFields,
              ...controlValveFields,
              ...isolationValveFields,
            ]}
          />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
