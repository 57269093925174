import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { SpecOptionLoader } from "./SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { ElementSpec } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateElementSpecificationRequest } from "assets/api";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface ElementSpecificationCardProps {
  assetType: AssetType;
  element: ElementSpec;
  assetSpec?: ElementSpec;
  recordId?: number;
}

export const ElementSpecificationCard = (props: ElementSpecificationCardProps) => {
  const { assetType, element, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdateElementSpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(element);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          element: values,
        });
      })();
    }
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <SpecOptionLoader type="ValveOrElementSize">
      {(options) => <EditableAutocompleteFormItem label="Size" fieldName="size" {...options} />}
    </SpecOptionLoader>,
    <SpecOptionLoader type="PressureClass">
      {(options) => <EditableAutocompleteFormItem label="Pressure class" fieldName="pressureClass" {...options} />}
    </SpecOptionLoader>,
    <EditableTextFieldFormItem label="k-Factor" fieldName="kFactor" />,
    <EditableTextFieldFormItem label="Cal number" fieldName="calNumber" />,
  ];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Element"
            specs={fields}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(element)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard title="Element" specs={fields} />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
