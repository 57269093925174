import React from "react";
import { Box, Container, Paper, Stack, Tab, Tabs } from "@mui/material";
import { useState } from "react";
import { PageTitle } from "app/mui/common/headers/PageTitle";
import Help from "@mui/icons-material/Help";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { HelpIcon } from "icons/HelpIcon";
import { useUserContext } from "app";
import { TutorialsPage } from "./tutorials/tutorialsPage";
import { TabPanel } from "app/mui/tabs/TabPanel";

enum TabIndex {
  ReleaseNotes = 0,
  Tutorials = 1,
}

const pageActions = () => {
  return createStaticActions((actionDispatcher, userPermissions) => {
    return [
      {
        icon: <HelpIcon />,
        label: "Placeholder",
        hidden: false,
      },
    ];
  });
};

export const HelpPage = () => {
  const [tabIndex, setTabIndex] = useState(0);
  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };
  var { userPermissions } = useUserContext();
  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(), actionDispatcher);

  return (
    <Container>
      <Paper sx={{ p: 3 }}>
        <Stack direction="column" spacing={3}>
          <Stack direction="row" justifyContent="space-between" alignItems="center">
            <PageTitle title="Help" icon={<Help />} />
            <Box sx={{ marginLeft: 2 }}>
              {userPermissions.userCanEditReleaseNotes && <ActionsDropdownMenu primary actions={actions} />}
            </Box>
          </Stack>
          <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
            <Tabs value={tabIndex} onChange={handleChangeTab}>
              <Tab label="Release Notes" value={TabIndex.ReleaseNotes} />
              <Tab label="Tutorials" value={TabIndex.Tutorials} />
            </Tabs>
          </Box>
          <TabPanel value={tabIndex} index={TabIndex.Tutorials} sx={{ p: 2 }}>
            <TutorialsPage></TutorialsPage>
          </TabPanel>
        </Stack>
      </Paper>
    </Container>
  );
};
