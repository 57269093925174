import { Box } from "@mui/material";
import { AssetType } from "assets/models/assetType";

export const AssetImage = (props: { assetType: AssetType }) => {
  const imageMap = {
    [AssetType.ControlValve]: "/images/assets/control_valve.png",
    [AssetType.IsolationValve]: "/images/assets/isolation_valve.png",
    [AssetType.ReliefValve]: "/images/assets/relief_valve.png",
    [AssetType.ManualValve]: "/images/assets/manual_valve.png",
    [AssetType.Regulator]: "/images/assets/regulator.png",
    [AssetType.SteamTrap]: "/images/assets/steam_trap.png",
    [AssetType.Tank]: "/images/assets/tank.png",
    [AssetType.MotorOperatedValve]: "/images/assets/motor_operated_valve.png",
    [AssetType.Machinery]: "/images/assets/machinery.png",
    [AssetType.Measurement]: "/images/assets/measurement.png",
    [AssetType.General]: "/images/assets/general.png",
  };

  return (
    <Box
      borderColor="divider"
      sx={{ border: "solid 1px", borderRadius: "50%", width: "120px", height: "120px", overflow: "hidden" }}
      display="inline-block"
    >
      <img
        style={{ transform: "scale(1.01)", transformOrigin: "center" }}
        src={imageMap[props.assetType]}
        width="100%"
        height="100%"
      />
    </Box>
  );
};
