import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import {
  AccessorySpec,
  ActuatorSpec,
  Asset,
  AssetDigest,
  AssetFilters,
  ElementSpec,
  MeasurementSpec,
  PilotSpec,
  RegulatorSpec,
  SteamTrapSpec,
  TankSpec,
  ValveSpec,
} from "./models/asset";
import { AssetDetailView } from "./models/assetDetail";
import { AssetDevice } from "./models/assetDevice";
import { AssetType } from "./models/assetType";
import {
  AssetComponentAttributeUpdate,
  AssetComponentDetailView,
  AssetComponentFilters,
  AssetMachineryComponent,
  AssetMachineryComponentUpdate,
} from "./models/machineryComponent";
import { CreateAssetComponentsForm } from "./mui/dialogs/CreateMachineryComponents";
import { GeneralSpecificationModel } from "@bluemarvel/iris-common/model";

export const assetSpecificationUriMatchPatterns = [/api\/assetSpecifications\/.*/gi];

export const useGetAssetDetailByIdRequest = (id: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<AssetDetailView>(`/api/assets/detail/${id}`, assetsMutationUriMatchPatterns, {
    useNode: true,
  });
export const useGetAssetsByIdsRequest = (ids: number[]) =>
  useBaseAxiosGetRequestWithAutoRefresh<AssetDetailView[]>(
    !!ids && ids.length > 0 ? `/api/assets/detail?ids=${ids.join(",")}` : null,
    assetSpecificationUriMatchPatterns,
    { useNode: true }
  );

export const assetMachineryUriMatchPatterns = [/api\/assets\/machineryComponents.*/gi];

export const useGetFilteredAssetsRequest = (siteId: number) =>
  useBaseAxiosPostRequest<AssetFilters, AssetDetailView[]>(`api/assets/filtered/${siteId}`, {
    app: { hideFromAutosaver: true },
    useNode: true,
  });

export const useGetFilteredAssetComponentsRequest = (siteId: number, templateId: number) =>
  useBaseAxiosPostRequest<AssetComponentFilters, AssetComponentDetailView[]>(
    `api/assets/components/filtered/${siteId}`,
    {
      app: { hideFromAutosaver: true },
      useNode: true,
    }
  );

export const assetsMutationUriMatchPatterns = [/api\/assets.*/gi];

export const useCreateAssetRequest = () =>
  useBaseAxiosPostRequest<Partial<Asset>, AssetDigest>(`/api/assets`, { useNode: true });

export const useUpdateAssetRequest = () => useBaseAxiosPutRequest<Asset>(`/api/assets`, { useNode: true });

export const useDeleteAssetRequest = () => useBaseAxiosDeleteRequest((id) => `/api/assets/${id}`, { useNode: true });

export interface DuplicateAssetRequest {
  assetId: number;
  newTag: string;
}

export const useDuplicateAssetRequest = () =>
  useBaseAxiosPostRequest<DuplicateAssetRequest, AssetDigest>(`/api/assets/duplicate`, { useNode: true });

export const useGetAssetDevicesByAssetIdQuery = (assetId: number) =>
  useBaseAxiosGetRequest<AssetDevice[]>(`/api/assets/${assetId}/devices`);
export const useCreateAssetDeviceRequest = (assetId: number) =>
  useBaseAxiosPostRequest<Partial<AssetDevice>, AssetDevice>(`/api/assets/${assetId}/devices`, { useNode: true });
export const useUpdateAssetDeviceRequest = (assetId: number) =>
  useBaseAxiosPutRequest<AssetDevice>(`/api/assets/${assetId}/devices`, { useNode: true });
export const useDeleteAssetDeviceRequest = (assetId: number) =>
  useBaseAxiosDeleteRequest<number>((deviceId) => `/api/assets/${assetId}/devices/${deviceId}`, { useNode: true });

export const useDeleteAssetComponentRequest = () =>
  useBaseAxiosDeleteRequest<number>((componentId) => `/api/assets/machineryComponents/${componentId}`, {
    useNode: true,
  });

export const useGetAssetDeviceTypesRequest = (assetType: AssetType, requireSearchText: boolean) => {
  const request = useBaseAxiosGetRequest<string[]>(`api/assets/devices/deviceTypes/${assetType}`, {
    axios: {
      manual: requireSearchText,
    },
    useNode: true,
  });
  const call = (searchText?: string) => {
    if (!!searchText) {
      return request.call(`api/assets/devices/deviceTypes/${assetType}?searchText=${encodeURIComponent(searchText)}`);
    }
    return request.call();
  };
  return { ...request, call };
};

export const useUpdateSteamTrapSpecificationRequest = () =>
  useBaseAxiosPutRequest<SteamTrapSpec>(`/api/assetSpecifications/steamTrap`, { useNode: true });

export const useUpdateValveSpecificationRequest = () =>
  useBaseAxiosPutRequest<ValveSpec>(`/api/assetSpecifications/valve`, { useNode: true });

export const useUpdateActuatorSpecificationRequest = () =>
  useBaseAxiosPutRequest<ActuatorSpec>(`/api/assetSpecifications/actuator`, { useNode: true });

export const useUpdateAccessorySpecificationRequest = () =>
  useBaseAxiosPutRequest<AccessorySpec>(`/api/assetSpecifications/accessory`, { useNode: true });

export const useUpdateMeasurementSpecificationRequest = () =>
  useBaseAxiosPutRequest<MeasurementSpec>(`/api/assetSpecifications/measurement`, { useNode: true });

export const useUpdateElementSpecificationRequest = () =>
  useBaseAxiosPutRequest<ElementSpec>(`/api/assetSpecifications/element`, { useNode: true });

export const useUpdateTankSpecificationRequest = () =>
  useBaseAxiosPutRequest<TankSpec>(`/api/assetSpecifications/tank`, { useNode: true });

export const useUpdateRegulatorSpecificationRequest = () =>
  useBaseAxiosPutRequest<RegulatorSpec>(`/api/assetSpecifications/regulator`, { useNode: true });

export const useUpdatePilotSpecificationRequest = () =>
  useBaseAxiosPutRequest<PilotSpec>(`/api/assetSpecifications/pilot`, { useNode: true });

export const useUpdateGeneralSpecificationRequest = () =>
  useBaseAxiosPutRequest<GeneralSpecificationModel>(`/api/assetSpecifications/general`, { useNode: true });

// machinery
export const useGetAssetComponentsByIdQuery = (assetId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<AssetMachineryComponent[]>(
    `/api/assets/machineryComponents/${assetId}`,
    assetMachineryUriMatchPatterns,
    { useNode: true }
  );

export const useCreateAssetMachineryComponentsRequest = () =>
  useBaseAxiosPostRequest<CreateAssetComponentsForm, AssetMachineryComponent[]>(`/api/assets/machineryComponents`, {
    useNode: true,
  });

export const useUpdateAssetMachineryComponentRequest = (assetId: number) =>
  useBaseAxiosPutRequest<AssetMachineryComponentUpdate>(`/api/assets/machineryComponents/${assetId}`, {
    useNode: true,
  });

export const useUpdateAssetMachineryAttributeRequest = (assetId: number) =>
  useBaseAxiosPutRequest<AssetComponentAttributeUpdate[]>(`/api/assets/${assetId}/machineryAttribute`, {
    useNode: true,
  });
