import { DragDropContext, DropResult, Droppable } from "@hello-pangea/dnd";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Paper, Stack } from "@mui/material";
import { useUserContext } from "app";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { ActionMenuActionType, ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { AddIcon } from "icons/AddIcon";
import { sortBy } from "lodash";
import { FC, useCallback, useEffect, useState } from "react";
import { useGetRecommendationsRequest, useUpdateRecommendationRequest } from "recommendations/api";
import { RecordDetailView } from "records/models";
import { AddRecommendationDialog } from "recommendations/mui/dialogs/AddRecommendation";
import { RecommendationCard } from "recommendations/mui/models/RecommendationCard";
import { RecommendationModel } from "@bluemarvel/iris-common/model";

interface RecordRecommendationsProps {
  recordDetail: RecordDetailView;
}

type DialogActionsType = "addRecommendation" | null;
const validDialogActions = ["addRecommendation"];

export const RecordRecommendations: FC<RecordRecommendationsProps> = (props) => {
  const { recordDetail } = props;
  const record = recordDetail.model;

  const [recommendations, setRecommendations] = useState<RecommendationModel[]>([]);
  const getRecommendationsRequest = useGetRecommendationsRequest({ recordId: record.id });
  const updateRecommendationRequest = useUpdateRecommendationRequest();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<DialogActionsType>(null);

  const handleAction = useCallback((action: string, params: any) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const { userPermissions } = useUserContext();
  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );

  const isClosed = recordDetail.model.status === "Complete" || recordDetail.model.status === "Archived";
  const actionDispatcher = useActionDispatcher();

  const allRecommendationsExpanded = detailPanelExpandedRowIds.length === recommendations.length;

  const toggleExpand = useCallback((id: number) => {
    setDetailPanelExpandedRowIds((prev) => {
      const newExpanded = [...prev];
      const index = newExpanded.indexOf(id);
      if (index === -1) {
        newExpanded.push(id);
      } else {
        newExpanded.splice(index, 1);
      }
      return newExpanded;
    });
  }, []);

  useEffect(() => {
    if (getRecommendationsRequest.loading) {
      return;
    }

    setRecommendations(sortBy(getRecommendationsRequest.data, (x) => x.priority) || []);
  }, [getRecommendationsRequest.loading]);

  useEffect(() => {
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const actions: ActionMenuActionType[] = [
    {
      icon: <AddIcon />,
      label: "Add Recommendation",
      action: () => actionDispatcher.dispatch("addRecommendation"),
    },
    {
      icon: allRecommendationsExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />,
      label: allRecommendationsExpanded ? "Collapse All" : "Expand All",
      action: () => {
        if (allRecommendationsExpanded) {
          setDetailPanelExpandedRowIds([]);
        } else {
          const idsToExpand = recommendations.map((r) => r.id);
          setDetailPanelExpandedRowIds(idsToExpand);
        }
      },
      hidden: recommendations.length === 0,
    },
  ];

  const handleRowOrderChange = useCallback(
    (params: { id: number; targetIndex: number; originalIndex: number }) => {
      const originalRecommendation = recommendations.find((a) => a.id === params.id);
      const otherRecommendations = recommendations.filter((a) => a.id !== params.id);
      if (params.targetIndex !== params.originalIndex && originalRecommendation) {
        const newRecommendation: RecommendationModel = {
          ...originalRecommendation,
          priority: params.targetIndex + 1,
        };
        setRecommendations(sortBy([...otherRecommendations, originalRecommendation], (x) => x.priority));
        updateRecommendationRequest.call(newRecommendation);
      }
    },
    [updateRecommendationRequest]
  );

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    handleRowOrderChange({
      id: recommendations[source.index].id,
      originalIndex: source.index,
      targetIndex: destination.index,
    });
  };

  return (
    <>
      <Paper>
        <ExpansionPanel
          title="RECOMMENDATIONS"
          defaultExpanded={false}
          rightSideComponent={(userCanEdit || !isClosed) && <ActionsDropdownMenu actions={actions} />}
        >
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <Stack direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                  {recommendations.map((recommendation: RecommendationModel, index: number) => (
                    <RecommendationCard
                      record={record}
                      recommendation={recommendation}
                      index={index}
                      key={`${recommendation.id}-${recommendation.priority}`}
                      expanded={detailPanelExpandedRowIds.includes(recommendation.id)}
                      onExpandChange={() => toggleExpand(recommendation.id)}
                      canEdit={userCanEdit}
                      isClosed={isClosed}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </ExpansionPanel>
      </Paper>
      {openDialog === "addRecommendation" && <AddRecommendationDialog onClose={closeDialog} record={recordDetail} />}
    </>
  );
};
