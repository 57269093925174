import * as React from "react";
import { Record, useCloseOrOpenRecordRequest } from "records";
import { RecordDetailView } from "records/models";
import Modal from "reactstrap/lib/Modal";
import ModalBody from "reactstrap/lib/ModalBody";
import ModalHeader from "reactstrap/lib/ModalHeader";
import ModalFooter from "reactstrap/lib/ModalFooter";
import Button from "reactstrap/lib/Button";
import { FormGroup, Label, Form } from "reactstrap";
import Textarea from "react-textarea-autosize";
import { Checkbox } from "common";
import { useState } from "react";

interface Props {
  recordDetail: RecordDetailView;
  localRecord: Record;
  userCanViewKpis: boolean;
  close: () => void;
  onCompleted: (recordDetailView: RecordDetailView) => void;
}

export const CloseReopenRecordModal: React.FunctionComponent<Props> = (props) => {
  const closeOrOpenRecordRequest = useCloseOrOpenRecordRequest();
  const [comment, setComment] = useState("");

  const isClosed = props.recordDetail.model.status === "Complete";

  return (
    <Modal isOpen={true} container={"#bootstrap-modal-container"}>
      <ModalHeader>{isClosed ? "Reopen record" : "Close record"}</ModalHeader>
      <ModalBody>
        <Form className="comment-entry">
          <FormGroup>
            <Label>{isClosed ? "Reason for reopening" : "Resolution"}</Label>
            <Textarea
              className="form-control new-comment"
              name="new-comment"
              value={comment}
              onChange={(e) => setComment(e.target.value)}
            />
          </FormGroup>
        </Form>
      </ModalBody>

      <ModalFooter>
        <Button
          color="primary"
          onClick={async () => {
            closeOrOpenRecordRequest
              .call({
                recordId: props.recordDetail.id,
                status: isClosed ? "ImpactPartnerAction" : "Complete",
                comment,
              })
              .then((recordDetailView) => props.onCompleted(recordDetailView));
          }}
          disabled={!!!comment || !!!comment.trim() || closeOrOpenRecordRequest.loading}
        >
          {isClosed ? "Reopen record" : "Close record"}
        </Button>
        <Button color="secondary" onClick={() => props.close()} disabled={closeOrOpenRecordRequest.loading}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
