import Grid from "@mui/material/Grid";
import Stack from "@mui/material/Stack";
import { Routes, useUserContext } from "app";
import { EntityDetailsContainerSkeleton } from "app/mui/common/skeletons/EntityDetailsContainerSkeleton";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import PreviousRecordLoader from "app/mui/loaders/PreviousRecordLoader";
import { HealthHistory } from "assets/mui/sections/HealthHistory";
import { VisualIntegrityCard } from "assets/mui/sections/VisualIntegrityCard";
import { dateFormat } from "common/formats";
import moment from "moment";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import { useGetRecordByIdQuery, useUpdateRecordRequest } from "records/api";
import { RecordDetailView, RecordType, Status } from "records/models";
import { DiagnosticType } from "records/models/diagnosticType";
import { FieldInspectionReportCard } from "./cards/FieldInspectionReportCard";
import { PneumaticFullStrokeReportCard } from "./cards/PneumaticFullStrokeReportCard";
import { PneumaticPartialStrokeReportCard } from "./cards/PneumaticPartialStrokeReportCard";
import { ValveHealthCard } from "./cards/ValveHealthCard";
import { RecordAttachmentsAndHistory } from "./sections/RecordAttachmentsAndHistory";
import { RecordDetails } from "./sections/RecordDetails";
import { RecordKpis } from "./sections/RecordKpis/RecordKpis";
import { RecordObservations } from "./sections/RecordObservations";
import { RecordOutage } from "./sections/RecordOutage";
import { RecordRecommendations } from "./sections/RecordRecommendations";
import { RecordTesting } from "./sections/RecordTesting";
import { MotorOperatedReportCard } from "./cards/MotorOperatedReportCard";
import { isRecordObservationSettable } from "records/utilities";
import { VibrationHealthCard } from "./cards/VibrationHealthCard";
import { VibrationHealthHistory } from "assets/mui/sections/VibrationHealthHistory";
import { VibrationAnalysisReportCard } from "./cards/VibrationAnalysisReportCard";
import { RecordAssetSpecifications } from "./sections/RecordAssetSpecifications";
import { EquipmentHealthReportCard } from "./cards/EquipmentHealthReportCard";
import { EquipmentHealthCard } from "./cards/EquipmentHealthCard";

// V2 Layout of Record
export const RecordPage = () => {
  const routeMatch = useMatch(Routes.Record);
  const [record, setRecord] = useState<RecordDetailView | null>();

  const { data, loading } = useGetRecordByIdQuery(Number(routeMatch?.params?.id));

  useEffect(() => {
    setRecord(data);
  }, [data]);

  if (loading && !record) {
    return <EntityDetailsContainerSkeleton />;
  } else if (!record) {
    return null;
  }
  return <RecordPageWithForm recordDetail={record} />;
};

interface RecordPageWithFormProps {
  recordDetail: RecordDetailView;
}

const RecordPageWithForm = (props: RecordPageWithFormProps) => {
  const { recordDetail } = props;
  const record = recordDetail.model;
  const defaultValues = makeSafeForReactHookForm(record);
  const methods = useForm({ defaultValues });
  const updateRecord = useUpdateRecordRequest();
  const { userPermissions } = useUserContext();

  const saveChanges = () => {
    return methods.handleSubmit((values) => {
      updateRecord.call(values);
    })();
  };

  const isValveDiagnosticRecord = record.recordType === RecordType.ValveDiagnostic;
  const isPneumaticFullStroke = record.diagnosticType === DiagnosticType.PneumaticFullStroke;
  const isPneumaticPartialStroke = record.diagnosticType === DiagnosticType.PneumaticPartialStroke;
  const isMotorOperated = record.diagnosticType === DiagnosticType.MotorOperated;

  const isFieldInspectionRecord = record.recordType === RecordType.FieldInspection;
  const isVibrationAnalysisRecord = record.recordType === RecordType.VibrationAnalysis;
  const isOutageScopeRecord = record.recordType === RecordType.OutageScope;
  const isEquipmentHealth = record.recordType === RecordType.EquipmentHealth;

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permissions) =>
        permissions.userCanEditRecord(
          record.assets?.map((asset) => asset.assetType),
          record.recordType
        )
      }
    >
      <AutoSave defaultValues={defaultValues} onSubmit={saveChanges} />
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} xl={9}>
          <Stack spacing={2}>
            <RecordDetails recordDetail={recordDetail} methods={methods} saveChanges={saveChanges} />
            {isValveDiagnosticRecord && <RecordTesting />}
            {isEquipmentHealth && <RecordAssetSpecifications record={record} />}
            {isRecordObservationSettable(record.recordType) && <RecordObservations />}

            <RecordRecommendations recordDetail={recordDetail} />
            {isOutageScopeRecord && (
              <RecordOutage
                record={record}
                userCanEdit={userPermissions.userCanEditRecord(
                  record.assets?.map((asset) => asset.assetType),
                  record.recordType
                )}
              />
            )}

            {userPermissions.userCanViewKpi && <RecordKpis recordDetail={recordDetail} />}
            <RecordAttachmentsAndHistory recordDetail={recordDetail} />
          </Stack>
        </Grid>
        <Grid item xs={12} lg={4} xl={3}>
          <Stack spacing={2} direction={{ xs: "column", md: "row", lg: "column" }}>
            {isValveDiagnosticRecord && <ValveHealthCard health={recordDetail.model?.health} />}
            {isValveDiagnosticRecord && (
              <HealthHistory
                assetId={recordDetail.model?.assets[0]?.id}
                recordId={recordDetail.model.id}
                title="VALVE HEALTH HISTORY"
                showToolTip={true}
              />
            )}
            {isVibrationAnalysisRecord && <VibrationHealthCard health={recordDetail.model?.health} />}
            {isVibrationAnalysisRecord && (
              <VibrationHealthHistory assetId={recordDetail.model?.assets[0]?.id} recordId={recordDetail.model.id} />
            )}
            {isPneumaticFullStroke && (
              <PneumaticFullStrokeReportCard
                record={recordDetail.model}
                reportCard={recordDetail.reportCardPneumaticFullStroke}
              />
            )}
            {isPneumaticPartialStroke && (
              <PneumaticPartialStrokeReportCard
                record={recordDetail.model}
                reportCard={recordDetail.reportCardPneumaticPartialStroke}
              />
            )}
            {isMotorOperated && (
              <MotorOperatedReportCard record={recordDetail.model} reportCard={recordDetail.reportCardMotorOperated} />
            )}
            {isVibrationAnalysisRecord && (
              <VibrationAnalysisReportCard
                record={recordDetail.model}
                reportCard={recordDetail.reportCardVibrationAnalysis}
              />
            )}
            {isEquipmentHealth && recordDetail.reportCardEquipmentHealth && (
              <>
                <EquipmentHealthCard reportCard={recordDetail.reportCardEquipmentHealth} />
                <EquipmentHealthReportCard
                  record={recordDetail.model}
                  reportCard={recordDetail.reportCardEquipmentHealth}
                />
              </>
            )}
            {isFieldInspectionRecord && (
              <PreviousRecordLoader recordId={recordDetail.id}>
                {(recordLoaderData) => (
                  <VisualIntegrityCard
                    visualIntegrity={record?.asLeftVisualIntegrity}
                    siteId={record.siteId}
                    previousRecordId={recordLoaderData?.recordDetail?.id}
                    previousRecordDescription={
                      recordLoaderData?.recordDetail?.model.eventDate
                        ? `Last field inspection ${moment(recordLoaderData?.recordDetail?.model.eventDate).format(
                            dateFormat
                          )}`
                        : undefined
                    }
                  />
                )}
              </PreviousRecordLoader>
            )}
            {isFieldInspectionRecord && <FieldInspectionReportCard record={recordDetail.model} />}
          </Stack>
        </Grid>
      </Grid>
    </PermissionAwareFormProvider>
  );
};
