import { Accordion, AccordionDetails, AccordionSummary, Box, Stack, Typography } from "@mui/material";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { RecommendationModel } from "@bluemarvel/iris-common/model";
import { DragDropContext, Droppable, DropResult } from "@hello-pangea/dnd";
import { RecommendationCard } from "recommendations/mui/models/RecommendationCard";
import { sortBy } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { useGetRecommendationsRequest, useUpdateRecommendationRequest } from "recommendations/api";
import { ActionMenuActionType, ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { AddIcon } from "icons/AddIcon";
import { AddRecommendationDialog } from "recommendations/mui/dialogs/AddRecommendation";
import { DialogAction } from "app/mui/common/dialogs/DialogAction";

interface OperatingSystemRecommendationsProps {
  packagedSolutionId: number;
}

export const OperatingSystemRecommendations = (props: OperatingSystemRecommendationsProps) => {
  const { packagedSolutionId } = props;
  const [recommendations, setRecommendations] = useState<RecommendationModel[]>([]);
  const getRecommendationsRequest = useGetRecommendationsRequest({ packagedSolutionId });
  const updateRecommendationRequest = useUpdateRecommendationRequest();
  const [detailPanelExpandedRowIds, setDetailPanelExpandedRowIds] = useState<number[]>([]);
  const [openDialog, setOpenDialog] = useState<DialogAction | null>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const actions: ActionMenuActionType[] = [
    {
      icon: <AddIcon />,
      label: "Add Recommendation",
      action: () => setOpenDialog(DialogAction.AddRecommendation),
    },
  ];

  const toggleExpand = useCallback((id: number) => {
    setDetailPanelExpandedRowIds((prev) => (prev.includes(id) ? prev.filter((rowId) => rowId !== id) : [...prev, id]));
  }, []);

  useEffect(() => {
    if (getRecommendationsRequest.loading) {
      return;
    }

    setRecommendations(sortBy(getRecommendationsRequest.data, (x) => x.priority) || []);
  }, [getRecommendationsRequest.loading]);

  const handleRowOrderChange = useCallback(
    (params: { id: number; targetIndex: number; originalIndex: number }) => {
      const originalRecommendation = recommendations.find((a) => a.id === params.id);
      const otherRecommendations = recommendations.filter((a) => a.id !== params.id);
      if (params.targetIndex !== params.originalIndex && originalRecommendation) {
        const newRecommendation: RecommendationModel = {
          ...originalRecommendation,
          priority: params.targetIndex + 1,
        };
        setRecommendations(sortBy([...otherRecommendations, originalRecommendation], (x) => x.priority));
        updateRecommendationRequest.call(newRecommendation);
      }
    },
    [updateRecommendationRequest]
  );

  const onDragEnd = ({ destination, source }: DropResult) => {
    // dropped outside the list
    if (!destination) return;

    handleRowOrderChange({
      id: recommendations[source.index].id,
      originalIndex: source.index,
      targetIndex: destination.index,
    });
  };

  return (
    <Box sx={{ pt: "20px" }}>
      <Accordion>
        <AccordionSummary sx={{ flexDirection: "row-reverse" }} expandIcon={<ExpandMoreIcon />}>
          <Stack sx={{ px: "5px" }} direction="row" width="100%" justifyContent="space-between" alignItems="center">
            <Typography variant="subtitle1" fontWeight={"bold"}>
              Operating System Recommendations
            </Typography>
            <ActionsDropdownMenu rowActionMenu actions={actions} />
          </Stack>
        </AccordionSummary>
        <AccordionDetails sx={{ p: 0 }}>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="droppable-list">
              {(provided) => (
                <Stack direction="column" ref={provided.innerRef} {...provided.droppableProps}>
                  {recommendations.map((recommendation: RecommendationModel, index: number) => (
                    <RecommendationCard
                      recommendation={recommendation}
                      index={index}
                      key={`${recommendation.id}-${recommendation.priority}`}
                      expanded={detailPanelExpandedRowIds.includes(recommendation.id)}
                      onExpandChange={() => toggleExpand(recommendation.id)}
                      canEdit={true}
                      isClosed={false}
                    />
                  ))}
                  {provided.placeholder}
                </Stack>
              )}
            </Droppable>
          </DragDropContext>
        </AccordionDetails>
      </Accordion>
      {openDialog === DialogAction.AddRecommendation && (
        <AddRecommendationDialog onClose={closeDialog} packagedSolutionId={packagedSolutionId} />
      )}
    </Box>
  );
};
