import { DiagnosticType } from "./diagnosticType";

export function getValveDiagnosticTestCriteria(diagnosticType: DiagnosticType): string {
  switch (diagnosticType) {
    case DiagnosticType.PneumaticFullStroke:
      return (
        "<p><strong>Status Monitor (IN SERVICE)</strong><em> - Monitoring of instrument operational parameters and alerts</em></p>" +
        "<p><strong>Step Response Test</strong><em> - 5 Steps (0, 100, 0, 100, 50) / Ramp Time (0 sec) / Collection Time (15 sec) / Collection Interval (150 msec)</em></p>" +
        "<p><strong>Performance Step Test</strong> - <em>25 Steps (.25% to 10%) / Ramp Time (0 sec) / Collection Time (15 sec) / Collection Interval (150 msec)</em></p>" +
        "<p><strong>Signature Test</strong> - <em>Input Start/End (-25%/125%) / Scan Time (Actuator Specific) / Collection Interval (150 msec)</em></p>"
      );
    case DiagnosticType.PneumaticPartialStroke:
      return (
        "<p><strong>Test Start Point:</strong></p>" +
        "<p><strong>Test Travel % Target:</strong></p>" +
        "<p><strong>PST Pressure Limit:</strong></p>" +
        "<p><strong>Maximum Allowable Travel:</strong></p>" +
        "<p><strong>Minimum Travel Movement:</strong></p>" +
        "<p><strong>SOV Test:</strong></p>"
      );
    case DiagnosticType.MotorOperated:
      return "";
  }
}
