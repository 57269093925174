import { useCallback, useEffect } from "react";
import { Box, LinearProgress, Stack } from "@mui/material";
import { useGetFilteredRecommendationDetailsBySiteRequest } from "recommendations/api";
import { useSiteContext } from "app";
import { useGridApiRef } from "@mui/x-data-grid-pro";
import { RecommendationDetailView, RecommendationFilters, RecordType } from "records/models";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import {
  createStaticActions,
  createStaticRowActions,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { ActionDispatcherApi, useActionDispatcher } from "app/mui/ActionDispatcher";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import { BusinessAreaContextProvider, useBusinessArea } from "app/mui/BusinessAreaContext";
import DownloadIcon from "@mui/icons-material/Download";
import moment from "moment";
import { dateFormat } from "common";
import { RecommendationsListDataViz } from "./visualizations/RecommendationsListDataViz";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { Site } from "sites/models";

const pageActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <DownloadIcon />,
    label: "Export current view",
    action: () => actionDispatcher.dispatch("ExportCurrentViewCSV"),
    hidden: !userPermissions.userCanExportAssetList,
  },
]);

export const RecommendationsList = () => {
  const actionDispatcher = useActionDispatcher();

  const { businessArea } = useBusinessArea();

  const site = useSiteContext().currentSite!;
  const siteId = site.id;
  const recommendationsRequest = useGetFilteredRecommendationDetailsBySiteRequest(siteId);
  const recommendationsDetails = recommendationsRequest.data?.filter((r) => r.record.model.recordType !== RecordType.EquipmentHealth);

  useEffect(() => {
    const filters = new RecommendationFilters();
    filters.businessArea = businessArea?.toString() ?? null;
    recommendationsRequest.call({
      filters,
    });
  }, [businessArea]);

  const actions = useStaticActions(pageActions, actionDispatcher);

  const apiRef = useGridApiRef();

  return (
    <Stack spacing={2}>
      <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
        <RecommendationsListDataViz apiRef={apiRef} loading={recommendationsRequest.loading} />
        <ActionsDropdownMenu primary actions={actions} />
      </Stack>

      <BusinessAreaContextProvider>
        <FilteredRecommendationsTable
          apiRef={apiRef}
          actionDispatcher={actionDispatcher}
          rows={recommendationsDetails ?? []}
          loading={recommendationsRequest.loading}
          site={site}
        />
      </BusinessAreaContextProvider>
    </Stack>
  );
};

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  // no actions yet.
]);

interface FilteredRecommendationsTableProps {
  apiRef: React.MutableRefObject<GridApiPro>;
  actionDispatcher: ActionDispatcherApi;
  loading: boolean;
  rows: RecommendationDetailView[];
  site: Site;
}

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Recommendations", { includeUserDefinedTabs: true, includeBusinessAreaFilter: true }),
};

const FilteredRecommendationsTable = (props: FilteredRecommendationsTableProps) => {
  const getTableRowActions = useStaticRowActions(rowActions, props.actionDispatcher);

  const handleAction = useCallback((action: string, params: any) => {
    if (action === "ExportCurrentViewCSV") {
      props.apiRef.current.exportDataAsCsv({
        fileName: `${props.site.name} Recommendations CurrentView ${moment().format(dateFormat)}`,
        utf8WithBom: true,
      });
    }
  }, []);

  useEffect(() => {
    props.actionDispatcher.subscribe(handleAction);
    return () => props.actionDispatcher.unsubscribe(handleAction);
  }, []);

  return (
    <>
      <Box sx={{ height: "700px", width: "100%" }}>
        <MetadataColumnProvider type="RecommendationDetailViewModel" getActions={getTableRowActions}>
          {(columns) => (
            <StripedDataGrid
              apiRef={props.apiRef}
              density="compact"
              loading={props.loading}
              components={GridComponents}
              columns={columns}
              rows={props.rows}
            />
          )}
        </MetadataColumnProvider>
      </Box>
    </>
  );
};
