import { Paper, Slider, Stack, Typography } from "@mui/material";
import { HealthBadge } from "assets/mui/health/HealthBadge";
import { TrafficLight } from "assets/mui/health/TrafficLight";
import { getColorForHealthByFourPointScore } from "common";
import { getTrafficLightOption, RecordDetailView } from "records";
import { ReportCardPackagedSolution } from "../reportCardPackageSolution";
import { IrisTooltip } from "app";
import { IrisColors } from "app/mui/theme";
import InfoIcon from "@mui/icons-material/Info";

interface HealthSliderProps {
  header: string;
  score: number;
}

const HealthSlider = (props: HealthSliderProps) => (
  <Stack width={"100%"}>
    <Typography variant="caption">{props.header}</Typography>
    <Slider
      size="small"
      disabled
      max={4}
      value={props.score}
      sx={{
        "& .MuiSlider-thumb": {
          color: getColorForHealthByFourPointScore(props.score),
        },
      }}
    />
  </Stack>
);

interface PackagedSolutionOverallHealthProps {
  reportCard: ReportCardPackagedSolution;
  equipmentHealth: RecordDetailView[];
}

export const PackagedSolutionOverallHealth = (props: PackagedSolutionOverallHealthProps) => {
  const { reportCard, equipmentHealth } = props;
  const asFoundOperatingSystemHealth = Math.max(
    reportCard.asFoundCondition_hardware ?? 0,
    reportCard.asFoundCondition_performance ?? 0,
    reportCard.asFoundCondition_security ?? 0,
    reportCard.asFoundCondition_software ?? 0
  );
  const asLeftOperatingSystemHealth = Math.max(
    reportCard.asLeftCondition_hardware ?? 0,
    reportCard.asLeftCondition_performance ?? 0,
    reportCard.asLeftCondition_security ?? 0,
    reportCard.asLeftCondition_software ?? 0
  );

  const reportCardEquipmentHealth = equipmentHealth.flatMap((e) =>
    e.reportCardEquipmentHealth ? [e.reportCardEquipmentHealth] : []
  );
  const asFoundEquipmentHealth = Math.max(
    ...reportCardEquipmentHealth.map((e) => e.asFound_accuracyCalibration ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asFound_dataIntegrityMaintenance ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asFound_measurementSystemIntegrity ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asFound_reportingAuditing ?? 0)
  );

  const asLeftEquipmentHealth = Math.max(
    ...reportCardEquipmentHealth.map((e) => e.asLeft_accuracyCalibration ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asLeft_dataIntegrityMaintenance ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asLeft_measurementSystemIntegrity ?? 0),
    ...reportCardEquipmentHealth.map((e) => e.asLeft_reportingAuditing ?? 0)
  );

  const asFoundOverall = Math.max(asFoundOperatingSystemHealth, asFoundEquipmentHealth);
  const asLeftOverall = Math.max(asLeftOperatingSystemHealth, asLeftEquipmentHealth);

  return (
    <Paper sx={{ p: 2, minHeight: "200px", display: "flex", flexDirection: "column", width: "100%" }}>
      <Stack justifyContent="space-between" direction="row" alignItems={"center"}>
        <Typography variant="h6">OVERALL HEALTH</Typography>
        <IrisTooltip
          title={
            <>
              <p>
                The Overall Health metric evaluates the condition of the TruckVue/RailVue system during a PMA site
                visit, using a combined analysis of the operating system and equipment.
              </p>
              <ul style={{ listStyleType: "none", padding: 0, margin: 0 }}>
                <li>Good &ndash; no areas of concern present</li>
                <li>
                  Minor &ndash; small concerns that could be rectified through planned maintenance and/or design change
                </li>
                <li>
                  Moderate &ndash; areas of elevated concern that may be harmful to equipment, personnel, or environment
                </li>
                <li>
                  Severe &ndash; significant concerns that require immediate attention, to prevent arm to equipment,
                  personnel, environment, or ability to operate safely
                </li>
              </ul>
            </>
          }
        >
          <span>
            <InfoIcon sx={{ color: IrisColors.gray500, verticalAlign: "middle", fontSize: "1.7rem" }} />
          </span>
        </IrisTooltip>
      </Stack>
      <Stack direction="row" alignItems="center" justifyContent="space-evenly" sx={{ p: 2 }}>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="subtitle2">As Found</Typography>
          <TrafficLight score={asFoundOverall} />
          <HealthBadge health={getTrafficLightOption(asFoundOverall).label} />
          <HealthSlider header="Operating System Health" score={asFoundOperatingSystemHealth} />
          <HealthSlider header="Equipment Health" score={asFoundEquipmentHealth} />
        </Stack>
        <Stack alignItems="center" spacing={2}>
          <Typography variant="subtitle2">As Left</Typography>
          <TrafficLight score={asLeftOverall} />
          <HealthBadge health={getTrafficLightOption(asLeftOverall).label} />
          <HealthSlider header="Operating System Health" score={asLeftOperatingSystemHealth} />
          <HealthSlider header="Equipment Health" score={asLeftEquipmentHealth} />
        </Stack>
      </Stack>
    </Paper>
  );
};
