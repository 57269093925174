import { yupResolver } from "@hookform/resolvers/yup";
import {
  Button,
  Card,
  CardContent,
  DialogActions,
  DialogContent,
  DialogTitle,
  MenuItem,
  Stack,
  Typography,
} from "@mui/material";
import { Asset } from "assets/models/asset";
import { FormProvider, useForm } from "react-hook-form";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { RichTextEditor } from "app/mui/forms/RichTextEditor";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { FollowUpDateFormItem } from "app/mui/forms/Date/FollowUpDateFormItem";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";
import { RecordTypesLoader } from "app/mui/loaders/RecordTypesLoader";
import { EventsLoader } from "app/mui/loaders/EventsLoader";
import { formatRoutePath, Routes } from "app";
import {
  RecordType,
  useCreateRecordRequest,
  useGetUsedRecordsEventIdsForRecord,
  Record,
  singleAssetRecordTypes,
  RecordDetailView,
  RecordFieldLabel,
  isRecordStatusSettable,
  isRecordObservationSettable,
  isRecordDescriptionSettable,
  useGetRecordDetailsByFilterRequest,
  RecordFilters,
  getValveDiagnosticTestCriteria,
} from "records";
import { AvailableFailureModesLoader } from "app/mui/loaders/AvailableFailureModesLoader";
import { RecordStatusLoader } from "app/mui/RecordStatusLoader";
import SplitButton from "app/mui/SplitButton";
import { useSiteContext } from "app";
import { Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import * as yup from "yup";
import { AnyObject } from "yup/lib/types";
import { useEffect, useState } from "react";
import { DiagnosticTypesLoader } from "app/mui/loaders/DiagnosticTypesLoader";

interface CreateRecordDialogProps extends BaseDialogProps {
  asset?: Asset | null;
  onComplete?: (record: RecordDetailView) => void;
}

const requiredMessage = "required";

const testAssetsCountAgainstRecordType: yup.TestFunction<any[] | undefined, AnyObject> = (assets, ctx) => {
  if (singleAssetRecordTypes.includes(ctx.parent.recordType)) {
    if (!assets || assets.length !== 1) {
      return false;
    }
  }

  // all other types allow any other number of assets, including 0
  return true;
};

const validationSchema = yup.object({
  assets: yup
    .array()
    .test("record-type-requires-single-asset", "one asset must be selected", testAssetsCountAgainstRecordType),
  recordType: yup.string().required(requiredMessage),
  diagnosticType: yup.string().when("recordType", {
    is: RecordType.ValveDiagnostic,
    then: yup.string().nullable().required(requiredMessage),
  }),

  description: yup.string().when("recordType", (recordType, schema) => {
    return recordType === RecordType.ValveDiagnostic ||
      recordType === RecordType.FieldInspection ||
      recordType === RecordType.VibrationAnalysis ||
      recordType === RecordType.OutageScope
      ? schema.notRequired()
      : schema.required(requiredMessage);
  }),
  testResults: yup.string().notRequired(),
  followUp: yup.date().notRequired().min(moment().startOf("day").toDate(), "must be in the future"),
  event: yup.number().when("recordType", {
    is: RecordType.OutageScope,
    then: yup.number().required(requiredMessage),
  }),
  eventDate: yup.date().when("recordType", {
    is: (type: any) => type !== RecordType.OutageScope,
    then: yup.date().required(requiredMessage).max(moment().endOf("day").toDate(), "must be in the past"),
  }),
  status: yup.string().when("recordType", {
    is: (type: any) => isRecordStatusSettable(type),
    then: yup.string().required(requiredMessage),
  }),
  resolution: yup.string().when("status", {
    is: "Complete",
    then: yup.string().required(requiredMessage),
  }),
});

export const CreateRecordDialog = (props: CreateRecordDialogProps) => {
  const currentSiteWithNavigation = useSiteContext().currentSiteWithNavigation!;
  const navigate = useNavigate();
  const site = useSiteContext().currentSite!;
  const recordsRequest = useGetRecordDetailsByFilterRequest(site.id);
  const [latestRecord, setLatestRecord] = useState<RecordDetailView | null>(null);
  const createRecordRequest = useCreateRecordRequest();

  const defaultValues = {
    assets: props.asset ? [props.asset] : [],
    recordType: null,
    status: "Identified",
    eventDate: new Date(),
  };
  const methods = useForm<any>({
    defaultValues,
    resolver: yupResolver(validationSchema),
    reValidateMode: "onChange",
    shouldUnregister: true,
  });

  const assets = methods.watch("assets");
  const recordType = methods.watch("recordType");
  const status = methods.watch("status");

  useEffect(() => {
    methods.setValue("assets", props.asset ? [props.asset] : []);
  }, [props.asset]);

  const disabledRecordsEventIds = useGetUsedRecordsEventIdsForRecord({
    assets: assets || [],
    recordsEventId: null,
    recordType: recordType,
  });

  const isValveDiagnostic = recordType === RecordType.ValveDiagnostic;
  const isFieldInspection = recordType === RecordType.FieldInspection;
  const isOutageScope = recordType === RecordType.OutageScope;
  useEffect(() => {
    setLatestRecord(null);
    if (assets.length > 0 && (isValveDiagnostic || isFieldInspection)) {
      const filters = new RecordFilters();
      filters.assetIds = assets.map((asset) => asset.id);
      filters.recordTypes = [recordType];

      recordsRequest.call({ filters }).then((recordData) => {
        if (recordData.length > 0) {
          const latestRecord = recordData.reduce((latest, record) => {
            const recordDate = record.created ? new Date(record.created) : new Date(0);
            const latestDate = latest.created ? new Date(latest.created) : new Date(0);
            return recordDate > latestDate ? record : latest;
          });

          setLatestRecord(latestRecord);
        }
      });
    }
  }, [assets, recordType]);

  const submit = (navigateToRecord?: (recordId: number) => any) => {
    if (!currentSiteWithNavigation) return;

    methods.handleSubmit(
      (values) => {
        const valveDiagnostic = values.recordType === RecordType.ValveDiagnostic;
        const fieldInspection = values.recordType === RecordType.FieldInspection;
        const recommendationsHaveStatuses = valveDiagnostic || recordType === RecordType.Proactive;
        const recommendationsWithStatusesInitialStatus = "Identified";

        const status = recommendationsHaveStatuses
          ? recommendationsWithStatusesInitialStatus
          : values.status
            ? values.status
            : "Identified";

        const newRecord: Partial<Record> = {
          siteId: currentSiteWithNavigation.id,
          recordType: values.recordType!,
          description: valveDiagnostic
            ? "Valve diagnostic"
            : fieldInspection
              ? "Field inspection"
              : values.description ?? "",
          details: values.details,
          status: status,
          followUp: values.followUp ? moment(values.followUp).toDate() : null,
          assets: values.assets,
          failureModeId: values.failureMode,
          diagnosticType: valveDiagnostic ? values.diagnosticType : null,
          testCriteria: valveDiagnostic ? getValveDiagnosticTestCriteria(values.diagnosticType) : null,
          testResults: valveDiagnostic ? values.testResults?.toString("html") : null,
          observations: values.observations?.toString("html"),
          eventDate: recordType === RecordType.OutageScope ? null : moment(values.eventDate).toDate(),
          recordsEventId: recordType === RecordType.OutageScope ? values.event : null,
          keywords: [],
        };

        createRecordRequest
          .call({
            record: newRecord,
            resolution: newRecord.status === "Complete" ? values.resolution : null,
          })
          .then((newRecord) => {
            if (!!navigateToRecord) {
              navigateToRecord(newRecord.id);
            }
            props.onComplete?.(newRecord);
            props.onClose();
          });
      },
      (errs) => console.log(errs)
    )();
  };

  const title = props.asset ? (
    <Typography variant="h6" component="span">
      {"Create record for: "}
      <Typography variant="h6" component="span" sx={{ fontFamily: "Inconsolata, monospace" }}>
        {props.asset.tag}
      </Typography>
    </Typography>
  ) : (
    <Typography variant="h6" component="span">
      {"Create record"}
    </Typography>
  );

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        {title}
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "450px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <MultiAssetSelectFormItem
              label="Asset(s)"
              fieldName="assets"
              selectedAssets={props.asset ? [props.asset] : undefined}
            />
            <RecordTypesLoader assets={assets}>
              {(options) => (
                <AutocompleteFormItem label={RecordFieldLabel.RecordType} fieldName="recordType" {...options} />
              )}
            </RecordTypesLoader>

            {isValveDiagnostic && (
              <DiagnosticTypesLoader assets={assets}>
                {(options) => (
                  <AutocompleteFormItem
                    label={RecordFieldLabel.DiagnosticType}
                    fieldName="diagnosticType"
                    autoDefault={true}
                    {...options}
                  />
                )}
              </DiagnosticTypesLoader>
            )}

            {isValveDiagnostic && <RichTextEditor label="Test results" fieldName="testResults" />}
            {isRecordDescriptionSettable(recordType) && (
              <Stack spacing={1}>
                <TextFieldFormItem label="Description" fieldName="description" />
              </Stack>
            )}
            {isRecordObservationSettable(recordType) && (
              <RichTextEditor label="Observations" fieldName="observations" />
            )}

            {recordType && isOutageScope && (
              <EventsLoader disabledRecordsEventIds={disabledRecordsEventIds}>
                {(options) => <AutocompleteFormItem label="Event" fieldName="event" {...options} />}
              </EventsLoader>
            )}

            {recordType && !isOutageScope && (
              <Stack spacing={1}>
                <DateInputFormItem label="Occurrence date" fieldName="eventDate" />
                <AvailableFailureModesLoader assets={assets}>
                  {(options) => {
                    if (assets.length === 1 && options.options.length >= 1) {
                      return (
                        <AutocompleteFormItem
                          label="Failure mode (for a single asset which is part of an application)"
                          fieldName="failureMode"
                          {...options}
                        />
                      );
                    }
                    return null;
                  }}
                </AvailableFailureModesLoader>
              </Stack>
            )}

            {isRecordStatusSettable(recordType) && (
              <RecordStatusLoader>
                {(options) => <AutocompleteFormItem label="Status" fieldName="status" {...options} />}
              </RecordStatusLoader>
            )}

            {status === "Complete" && <TextFieldFormItem label="Resolution" fieldName="resolution" />}

            <FollowUpDateFormItem label="Follow up" fieldName="followUp" />
          </Stack>
          {(isValveDiagnostic || isFieldInspection) && (
            <Card variant="outlined" sx={{ mt: 2 }}>
              <CardContent>
                {isFieldInspection && (
                  <Typography sx={{ fontSize: 14 }}>
                    <strong>Note:</strong> If a previous record exists, the report card as-left values will be
                    transferred to the as-found values of the new record.
                    {latestRecord && (
                      <Link
                        to={formatRoutePath(Routes.Record, latestRecord.model)}
                        className="description"
                        style={{ display: "block", marginTop: 4 }}
                      >
                        {latestRecord.model.description}
                      </Link>
                    )}
                  </Typography>
                )}
                {isValveDiagnostic && (
                  <Typography sx={{ fontSize: 14 }}>
                    <strong>Note:</strong> If a previous record exists, the as-left values for valve health and report
                    card will be automatically transferred to the newly created record
                    {latestRecord && (
                      <Link
                        to={formatRoutePath(Routes.Record, latestRecord.model)}
                        className="description"
                        style={{ display: "block", marginTop: 4 }}
                      >
                        {latestRecord.model.description}
                      </Link>
                    )}
                  </Typography>
                )}
              </CardContent>
            </Card>
          )}
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={() => props.onClose()}>Cancel</Button>
          <SplitButton
            buttonGroupProps={{ variant: "contained", disabled: createRecordRequest.loading }}
            primaryButton={
              <Button
                onClick={() => {
                  submit((id) => {
                    navigate(currentSiteWithNavigation.routeTo.Record({ id }));
                  });
                }}
              >
                Create And Open
              </Button>
            }
            additionalActions={[<MenuItem onClick={() => submit()}>Create</MenuItem>]}
          />
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
