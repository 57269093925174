import { PackagedSolutionDetailViewModel } from "@bluemarvel/iris-common/model";
import { Box, Paper, Tab, Tabs } from "@mui/material";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import { useState } from "react";
import { PackagedSolutionRecordTab } from "./components/PackagedSolutionRecordTab";

interface PackagedSolutionTabSectionsProps {
  packagedSolutionDetail: PackagedSolutionDetailViewModel;
}

export const PackagedSolutionTabSections = ({ packagedSolutionDetail }: PackagedSolutionTabSectionsProps) => {
  const [tabIndex, setTabIndex] = useState(0);
  const packagedSolution = packagedSolutionDetail.model;

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Records" />
          <Tab label="Attachments" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <PackagedSolutionRecordTab packagedSolutionId={packagedSolution.id} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
        <AttachmentsArea
          parentType="PackagedSolution"
          parentId={packagedSolution.id}
          parentTag={packagedSolution.name}
        />
      </TabPanel>
    </Paper>
  );
};
