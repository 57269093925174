import React from "react";
import { Box, CircularProgress, Container, Link, Modal, Paper, Stack, Typography } from "@mui/material";
import { TutorialNode } from "tutorials/bs/models";
import { useState } from "react";
import { useGetTutorialsRequest } from "common";

export const TutorialsPageOld = () => {
  return (
    <Container>
      <Paper sx={{ p: 3 }}>
        <Stack spacing={3}>
          <Typography variant="h5">Tutorials</Typography>
          <TutorialsPageDataProvider>
            {(tutorials) => {
              return tutorials.map((tutorial) => <HelpTutorialNode tutorialNode={tutorial} key={tutorial.id} />);
            }}
          </TutorialsPageDataProvider>
        </Stack>
      </Paper>
    </Container>
  );
};

const TutorialsPageDataProvider = (props: { children: (tutorialNode: TutorialNode[]) => React.ReactNode }) => {
  const query = useGetTutorialsRequest();
  if (query.loading) {
    return <CircularProgress size="24px" />;
  }

  if (query.error || !query.data) return <div></div>;

  return <>{props.children(query.data || [])}</>;
};

const HelpTutorialNode = (props: { tutorialNode: TutorialNode }) => {
  const [open, setOpen] = useState<boolean>(false);

  return (
    <Box p={1}>
      {props.tutorialNode.source ? (
        <Link component="button" onClick={() => setOpen(true)}>
          {props.tutorialNode.title}
        </Link>
      ) : (
        <Typography variant="h6">{props.tutorialNode.title}</Typography>
      )}
      {props.tutorialNode.source && (
        <Modal open={open} onClose={() => setOpen(false)}>
          <Box
            onClick={() => setOpen(false)}
            sx={{
              height: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div dangerouslySetInnerHTML={{ __html: props.tutorialNode.source }}></div>
          </Box>
        </Modal>
      )}
      <Stack>
        {props.tutorialNode.children?.map((node) => (
          <HelpTutorialNode tutorialNode={node} key={node.id} />
        ))}
      </Stack>
    </Box>
  );
};
