import React, { useCallback } from "react";
import { Box, Paper, LinearProgress, Tabs, Tab, Stack } from "@mui/material";
import { GridValidRowModel, useGridApiRef } from "@mui/x-data-grid-pro";
import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import CreateNewFolderRoundedIcon from "@mui/icons-material/CreateNewFolderRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AddchartIcon from "@mui/icons-material/Addchart";
import { AssetDetailView } from "assets/models/assetDetail";
import { RecordDetailView, RecordType, useGetRecordsByAssetQuery } from "records";
import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { MetadataColumnProvider } from "app/mui/tables/metadata";
import { TabPanel } from "app/mui/tabs/TabPanel";
import { AttachmentsArea } from "attachments/mui/AttachmentsArea";
import { StripedDataGrid } from "app/mui/tables/StripedDataGrid";
import { ToolbarWithTabViews } from "app/mui/tables/ToolbarWithTabViews";
import {
  createStaticActions,
  createStaticRowActions,
  useStaticActions,
  useStaticRowActions,
} from "app/mui/tables/utils/createStaticColumns";
import { CreateRecordDialog } from "assets/mui/dialogs/CreateRecord";
import { ActionDispatcherApi, useActionDispatcher } from "app/mui/ActionDispatcher";
import { GenerateReportDialog } from "records/mui/dialogs/GenerateReport";
import { CloseReopenRecordDialog } from "records/mui/dialogs/CloseRecord";
import { DuplicateRecordDialog } from "records/mui/dialogs/DuplicateRecord";
import { AssignKpiTemplateDialog } from "records/mui/dialogs/AssignKpiTemplate";
import { RecordsListDataViz } from "tasks/mui/visualizations/RecordsListDataViz";
import { useUserContext } from "app";
import AssetHistory from "./AssetHistory";

interface AssetRecordsAndAttachmentsProps {
  asset: AssetDetailView | null;
}

type OpenDialogTypes = "create-record" | "generate-report" | "duplicate-record" | "close-record" | "assign-kpi" | null;

export const AssetRecordsAndAttachments = (props: AssetRecordsAndAttachmentsProps) => {
  const [tabIndex, setTabIndex] = React.useState<number>(0);

  const handleChangeTab = (_: any, newValue: number) => {
    setTabIndex(newValue);
  };
  const { userPermissions } = useUserContext();

  const userCanEditAsset = props.asset?.model?.assetType
    ? userPermissions.userCanEditAsset(props.asset?.model?.assetType)
    : false;

  return (
    <Paper>
      <Box sx={{ borderBottom: 1, borderColor: "divider", my: 2, px: 2 }}>
        <Tabs value={tabIndex} onChange={handleChangeTab}>
          <Tab label="Records" />
          <Tab label="Attachments" />
          <Tab label="History" />
        </Tabs>
      </Box>
      <TabPanel value={tabIndex} index={0} sx={{ p: 2 }}>
        <AssetRecordsTab asset={props.asset} />
      </TabPanel>
      <TabPanel value={tabIndex} index={1} sx={{ p: 2 }}>
        <AttachmentsArea
          parentType="Asset"
          parentId={props.asset!.id}
          parentTag={props.asset!.model.tag}
          readOnly={!userCanEditAsset}
        />
      </TabPanel>
      <TabPanel value={tabIndex} index={2} sx={{ p: 2 }}>
        <AssetHistory assetId={props.asset!.id} />
      </TabPanel>
    </Paper>
  );
};

const GridComponents = {
  LoadingOverlay: LinearProgress,
  Toolbar: ToolbarWithTabViews("Asset-Records"),
};

const recordsTabActions = createStaticActions((actionDispatcher, userPermissions) => [
  {
    icon: <CreateNewFolderRoundedIcon />,
    label: "Create Record",
    action: () => actionDispatcher.dispatch("create-record"),
    hidden: !userPermissions.userCanEditRecords,
  },
]);

const rowActions = createStaticRowActions((actionDispatcher, permissions) => (params) => [
  {
    icon: <AssessmentRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch("generate-report", params.row);
    },
    label: "Generate report",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <TaskOutlinedIcon />,
    onClick: () => {
      actionDispatcher.dispatch("close-record", params.row);
    },
    label: params.row.model.closed ? "Reopen Record" : "Close Record",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <ContentCopyRoundedIcon />,
    onClick: () => {
      actionDispatcher.dispatch("duplicate-record", params.row);
    },
    label: "Duplicate",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
  {
    icon: <AddchartIcon />,
    onClick: () => {
      actionDispatcher.dispatch("assign-kpi", params.row);
    },
    label: "Assign KPI",
    showInMenu: true,
    hidden: !permissions.userCanEditRecords,
  },
]);

const AssetRecordsTab = (props: { asset: AssetDetailView | null }) => {
  const assetRecordsRequest = useGetRecordsByAssetQuery(props.asset ? [props.asset?.id] : []);
  const recordDetails = assetRecordsRequest.data?.filter((r) => r.model.recordType !== RecordType.EquipmentHealth);

  const [openDialog, setOpenDialog] = React.useState<OpenDialogTypes>(null);
  const [recordForDialog, setRecordForDialog] = React.useState<RecordDetailView>();

  const closeDialog = () => setOpenDialog(null);

  const apiRef = useGridApiRef();
  const actionDispatcher = useActionDispatcher();

  const actions = useStaticActions(recordsTabActions, actionDispatcher);

  const handleAction = useCallback((action: string, params: any) => {
    if (action === "create-record") {
      setOpenDialog("create-record");
    }
    if (action === "generate-report") {
      setRecordForDialog(params);
      setOpenDialog("generate-report");
    }
    if (action === "duplicate-record") {
      setRecordForDialog(params);
      setOpenDialog("duplicate-record");
    }
    if (action === "close-record") {
      setRecordForDialog(params);
      setOpenDialog("close-record");
    }
    if (action === "assign-kpi") {
      setRecordForDialog(params);
      setOpenDialog("assign-kpi");
    }
  }, []);

  React.useEffect(() => {
    assetRecordsRequest.call();

    actionDispatcher.subscribe(handleAction);
    return () => actionDispatcher.unsubscribe(handleAction);
  }, []);

  const reloadRowInTable = (newRecord: RecordDetailView) => {
    if (apiRef.current.getRow(newRecord.id)) {
      apiRef.current.updateRows([newRecord]);
    } else {
      const oldRows: GridValidRowModel[] = [];
      apiRef.current.getRowModels().forEach((value) => {
        oldRows.push(value);
      });
      apiRef.current.setRows([...oldRows, newRecord]);
    }
  };

  return (
    <Box sx={{ height: "500px" }}>
      <Stack spacing={2} height="100%">
        <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
          <RecordsListDataViz apiRef={apiRef} loading={assetRecordsRequest.loading} />
          <ActionsDropdownMenu actions={actions} />
        </Stack>
        <AssetRecordsTable
          asset={props.asset}
          actionDispatcher={actionDispatcher}
          apiRef={apiRef}
          data={recordDetails ?? []}
          loading={assetRecordsRequest.loading}
        />
      </Stack>

      {openDialog === "create-record" && (
        <CreateRecordDialog onClose={closeDialog} asset={props.asset?.model!} onComplete={reloadRowInTable} />
      )}
      {openDialog === "generate-report" && <GenerateReportDialog onClose={closeDialog} record={recordForDialog!} />}
      {openDialog === "duplicate-record" && (
        <DuplicateRecordDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
      {openDialog === "close-record" && (
        <CloseReopenRecordDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
      {openDialog === "assign-kpi" && (
        <AssignKpiTemplateDialog onClose={closeDialog} record={recordForDialog!} onComplete={reloadRowInTable} />
      )}
    </Box>
  );
};

interface AssetRecordsTableProps {
  asset: AssetDetailView | null;
  actionDispatcher: ActionDispatcherApi;
  apiRef: React.MutableRefObject<GridApiPro> | undefined;
  data: RecordDetailView[];
  loading: boolean;
}
const AssetRecordsTable = (props: AssetRecordsTableProps) => {
  const getTableRowActions = useStaticRowActions(rowActions, props.actionDispatcher);

  return (
    <Box sx={{ height: "100%", width: "100%" }}>
      <MetadataColumnProvider type="RecordDetailViewModel" getActions={getTableRowActions}>
        {(columns) => (
          <StripedDataGrid
            apiRef={props.apiRef}
            density="compact"
            loading={props.loading}
            components={GridComponents}
            columns={columns}
            rows={props.data}
          />
        )}
      </MetadataColumnProvider>
    </Box>
  );
};
