import { Box, Divider, Grid, Paper, Stack } from "@mui/material";
import { useUserContext } from "app/contexts/UserContext";
import { ExpansionPanel } from "app/mui/ExpansionPanel";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { DateInputFormItem } from "app/mui/forms/Date/DateInputFormItem";
import { FollowUpDateFormItem } from "app/mui/forms/Date/FollowUpDateFormItem";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";
import { AvailableFailureModesLoader } from "app/mui/loaders/AvailableFailureModesLoader";
import { EventsLoader } from "app/mui/loaders/EventsLoader";
import { RecordFlagsControl } from "flags/mui/RecordFlagsControl";
import { KeywordsField } from "keywords/mui/KeywordsField";
import { UseFormReturn } from "react-hook-form";
import { Record, RecordDetailView, RecordFieldLabel, RecordType } from "records/models";
import { useGetUsedAssetIdsForRecord, useGetUsedRecordsEventIdsForRecord } from "records/utilities";
import { RecordAssets } from "./RecordAssets";
import { RecordHeader } from "./RecordHeader";
import { RecordStatusFormItem } from "app/mui/forms/Select/RecordStatusFormItem";

interface RecordDetailsProps {
  recordDetail: RecordDetailView;
  methods: UseFormReturn<Record>;
  saveChanges: (onValid?: () => void) => void;
}

export const RecordDetails = (props: RecordDetailsProps) => {
  const { recordDetail, methods, saveChanges } = props;

  const record = recordDetail.model;
  const isOutageScope = record.recordType === RecordType.OutageScope;
  const isEquipmentHealth = record.recordType === RecordType.EquipmentHealth;

  const recordIsComplete = recordDetail.model.status === "Complete";
  const recordIsArchived = recordDetail.model.status === "Archived";
  const isClosed = recordIsComplete || recordIsArchived;

  const disabledAssetIds = useGetUsedAssetIdsForRecord(props.recordDetail.model);
  const { userPermissions } = useUserContext();

  const userCanEdit = userPermissions.userCanEditRecord(
    record.assets.map((asset) => asset.assetType),
    record.recordType
  );
  const userCanEditFlags = userPermissions.userCanEditRecordFlags;

  const disabledRecordsEventIds = useGetUsedRecordsEventIdsForRecord({
    assets: record.assets || [],
    recordsEventId: record.recordsEventId,
    recordType: record.recordType,
  });

  return (
    <Paper>
      <Box sx={{ p: 2 }}>
        <RecordHeader recordDetail={recordDetail} methods={methods} saveChanges={saveChanges} />
      </Box>
      <Divider />
      <ExpansionPanel title="DETAILS" defaultExpanded={true}>
        <Box sx={{ p: 2 }}>
          <Grid container spacing={3}>
            <Grid item xs={12} lg={4}>
              <Stack spacing={3}>
                {!userCanEdit || isClosed || isEquipmentHealth ? (
                  <RecordAssets assets={record.assets} label={RecordFieldLabel.Assets} />
                ) : (
                  <MultiAssetSelectFormItem
                    label={isOutageScope ? "Asset" : RecordFieldLabel.Assets}
                    fieldName="assets"
                    selectedAssets={record.assets}
                    disabledAssetIds={disabledAssetIds}
                  />
                )}
                {isOutageScope && !recordIsComplete && (
                  <RecordStatusFormItem
                    label={RecordFieldLabel.Status}
                    fieldName="status"
                    statusToExlude={["Complete"]}
                  />
                )}
                {!isOutageScope && (
                  <Box sx={{ width: "158px" }}>
                    <DateInputFormItem
                      label={RecordFieldLabel.EventDate}
                      fieldName="eventDate"
                      readOnly={!userCanEdit}
                      readOnlyValue={record.eventDate}
                    />
                  </Box>
                )}
                <Box sx={{ width: "340px" }}>
                  <FollowUpDateFormItem
                    label={RecordFieldLabel.FollowUp}
                    fieldName="followUp"
                    readOnly={!userCanEdit}
                    readOnlyValue={record.followUp}
                  />
                </Box>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={3}>
                {isOutageScope && (
                  <EventsLoader disabledRecordsEventIds={disabledRecordsEventIds}>
                    {(options) => <AutocompleteFormItem label="Event" fieldName="recordsEventId" {...options} />}
                  </EventsLoader>
                )}
                {!isOutageScope && (
                  <TextFieldFormItem
                    fieldName="customerContact"
                    label={RecordFieldLabel.CustomerContact}
                    readOnly={!userCanEdit}
                  />
                )}
                {!isOutageScope && (
                  <TextFieldFormItem fieldName="refWO" label={RecordFieldLabel.RefWo} readOnly={!userCanEdit} />
                )}
                <AvailableFailureModesLoader assets={record.assets}>
                  {(options) => {
                    if (record.assets.length === 1 && options.options.length >= 1) {
                      return (
                        <AutocompleteFormItem
                          label="Failure mode (for a single asset which is part of an application)"
                          fieldName="failureModeId"
                          {...options}
                        />
                      );
                    }
                    return null;
                  }}
                </AvailableFailureModesLoader>
              </Stack>
            </Grid>
            <Grid item xs={12} lg={4}>
              <Stack spacing={3}>
                <KeywordsField modelKeywords={record.keywords} formMethods={methods} readOnly={!userCanEdit} />
                <RecordFlagsControl recordId={record.id} modelFlags={record.flags} readOnly={!userCanEditFlags} />
              </Stack>
            </Grid>
          </Grid>
        </Box>
      </ExpansionPanel>
    </Paper>
  );
};
