import { GridActionsColDef, GridColDef, ValueOptions } from "@mui/x-data-grid-pro";
import { KnownModelType, MetaFieldDefinition, useGetMetadataColumnsRequest } from "common";
import { get } from "lodash";
import React from "react";
import { Site } from "sites";
import { GetActions } from "./models";
import { getCustomDataTypeCells, defaultValueFormatter } from "./rendering/CustomDataTypeCells";
import { renderCellWithOverflowSupport } from "./rendering/OverflowCellRenderer";

/*
 * !! IMPORTANT !!
 *
 * Once columns are loaded, they can never ever ever ever change!
 *
 */

export const useGeneratedColumns = (options: {
  type: KnownModelType;
  sites: Site[];
  templateId?: number;
  getActions?: GetActions;
}) => {
  const metadataRequest = useGetMetadataColumnsRequest(
    options.type,
    options.sites.map((s) => s.id),
    options.templateId
  );

  const metadata = metadataRequest.data || [];

  const columns: GridColDef[] = React.useMemo(
    () => getGeneratedColumnsFromMetadata(metadata, options.sites, options.getActions),
    [metadata, options.sites.map((s) => s.id)]
  );

  return { columns, loading: metadataRequest.loading };
};

export const getGeneratedColumnsFromMetadata = (
  metadata: MetaFieldDefinition[],
  sites: Site[],
  getActions?: GetActions
) => {
  const actionsColumns: GridActionsColDef[] = getActions
    ? [
        {
          field: "actions",
          type: "actions",
          width: 60,
          getActions: getActions,
          hideable: false,
        },
      ]
    : [];

  const customCells = getCustomDataTypeCells(sites);

  const dataColumns =
    metadata
      ?.filter((i) => i.muiGridColDef != null)
      .map((i) => {
        const type = i.muiGridColDef!.type;
        const customCellFn = customCells.hasOwnProperty(type) ? customCells[type] : null;
        const customCell = !!customCellFn ? customCellFn(i) : null;

        const renderFunction = customCell?.renderCell;

        return {
          type,
          field: i.uniqueField || i.field,
          headerName: i.muiGridColDef!.headerName,
          valueGetter: (params) => {
            if (i.supportedRecordTypes) {
              const recordType = params.row?.model?.recordType ?? params.row?.record?.model?.recordType;
              if (!i.supportedRecordTypes.includes(recordType)) {
                return null;
              }
            }
            return get(params.row, i.field);
          },
          valueOptions: i.muiGridColDef!.valueOptions as ValueOptions[],
          valueFormatter: defaultValueFormatter(i),

          // the location of the custom cell in the spread is important.
          // we want everything to have a default but be possibly overridden
          // by the custom cell def - except render cell, because we want
          // to wrap the possible result of render cell.
          ...customCell,
          renderCell: renderCellWithOverflowSupport(renderFunction),
        };
      }) ?? [];

  return [...dataColumns, ...actionsColumns];
};
