import * as React from "react";
import { useCallback, useState } from "react";
import { Box, Stack, Typography } from "@mui/material";

import { RecordDetailView, Record, RecordType } from "records/models";
import { RecordIcon } from "icons/RecordIcon";
import { RecordTypeBadge } from "app/mui/RecordTypeBadge";
import { RecordStatusBadge } from "app/mui/RecordStatusBadge";

import { ActionsDropdownMenu } from "app/mui/ActionsDropdownMenu";
import { createStaticActions, useStaticActions } from "app/mui/tables/utils/createStaticColumns";
import { useActionDispatcher } from "app/mui/ActionDispatcher";
import { CloseReopenRecordDialog } from "records/mui/dialogs/CloseRecord";

import TaskOutlinedIcon from "@mui/icons-material/TaskOutlined";
import AssessmentRoundedIcon from "@mui/icons-material/AssessmentRounded";
import ContentCopyRoundedIcon from "@mui/icons-material/ContentCopyRounded";
import CategoryRoundedIcon from "@mui/icons-material/CategoryRounded";
import DeleteForeverRoundedIcon from "@mui/icons-material/DeleteForeverRounded";

import { AssetIcon } from "icons/AssetIcon";

import { GenerateReportDialog } from "../dialogs/GenerateReport";
import { DuplicateRecordDialog } from "../dialogs/DuplicateRecord";
import { ChangeRecordTypeDialog } from "../dialogs/ChangeRecordType";
import { isSingleAssetRecordType, recordHasStatusDerivedFromRecommendations } from "records/utilities";
import { UseFormReturn } from "react-hook-form";
import { EditFieldDialog } from "app/mui/common/dialogs/EditFieldDialog";
import { RecordAssets } from "./RecordAssets";
import ConfirmationDialog from "common/mui/ConfirmationDialog";
import { useDeleteRecordRequest, useGetEquipmentHealthRequest } from "records/api";
import { useNotifications } from "app/contexts/NotificationContext";
import { useLocation, useNavigate } from "react-router-dom";
import { Routes, formatRoutePath } from "app/routes";
import { LastModified } from "app/mui/LastModified";
import { AssetDigest } from "assets";
import { useMobileView } from "app/hooks";
import { DiagnosticTypeBadge } from "app/mui/DiagnosticTypeBadge";
import { EquipmentHealthViewModel } from "@bluemarvel/iris-common/model";
import { InternalLink } from "common/mui/InternalLink";

interface RecordHeaderProps {
  recordDetail: RecordDetailView;
  methods: UseFormReturn<Record>;
  saveChanges: (onValid?: () => void) => void;
}

const pageActions = (recordDetails: RecordDetailView) => {
  return createStaticActions((actionDispatcher, userPermissions) => {
    const userCanEditRecord = userPermissions.userCanEditRecord(
      recordDetails.model.assets.map((asset) => asset.assetType),
      recordDetails.model.recordType
    );

    const recordIsComplete = recordDetails.model.status === "Complete";

    return [
      {
        icon: <TaskOutlinedIcon />,
        label: recordDetails.model.status === "Complete" ? "Reopen Record" : "Close Record",
        action: () => actionDispatcher.dispatch("closeRecord"),
        hidden:
          !userCanEditRecord ||
          (recordIsComplete && !userPermissions.userCanReopenRecord) ||
          (recordHasStatusDerivedFromRecommendations(recordDetails.model) && recordDetails.recommendations.length > 0),
      },
      {
        icon: <ContentCopyRoundedIcon />,
        label: "Duplicate record",
        action: () => actionDispatcher.dispatch("duplicateRecord"),
        hidden: !userPermissions.userCanEditRecords,
      },

      {
        icon: <AssessmentRoundedIcon />,
        label: "Generate report",
        action: () => actionDispatcher.dispatch("generateReport"),
        hidden: !userPermissions.userCanGenerateRecordReport,
      },
      {
        icon: <CategoryRoundedIcon />,
        label: "Change record type",
        action: () => actionDispatcher.dispatch("changeType"),
        hidden: !userCanEditRecord || isSingleAssetRecordType(recordDetails.model.recordType),
      },
      {
        icon: <AssetIcon />,
        label: "Edit description",
        action: () => actionDispatcher.dispatch("editDescription"),
        hidden: !userCanEditRecord,
      },
      {
        icon: <DeleteForeverRoundedIcon />,
        label: "Delete record",
        action: () => actionDispatcher.dispatch("deleteRecord"),
        hidden: !userCanEditRecord,
      },
    ];
  });
};

type DialogActionsType =
  | "closeRecord"
  | "generateReport"
  | "duplicateRecord"
  | "changeType"
  | "editDescription"
  | "deleteRecord"
  | null;
const validDialogActions = [
  "closeRecord",
  "generateReport",
  "duplicateRecord",
  "changeType",
  "editDescription",
  "deleteRecord",
];

export const RecordHeader = (props: RecordHeaderProps) => {
  const isMobileView = useMobileView();

  const { recordDetail, methods } = props;
  const record = recordDetail.model;

  const [isDeleting, setIsDeleting] = useState(false);

  const actionDispatcher = useActionDispatcher();
  const actions = useStaticActions(pageActions(recordDetail), actionDispatcher, [record]);
  const notifications = useNotifications();

  const navigate = useNavigate();
  const { state } = useLocation();
  const { returnTo } = state || {};

  const handleAction = useCallback((action: string) => {
    if (validDialogActions.indexOf(action) > -1) {
      setOpenDialog(action as DialogActionsType);
    }
  }, []);

  const deleteRecordRequest = useDeleteRecordRequest();
  const { data: equipmentHealth, call: getEquipmentHealthRequest } = useGetEquipmentHealthRequest(record.id);

  React.useEffect(() => {
    if (RecordType.EquipmentHealth === record.recordType) {
      getEquipmentHealthRequest();
    }
    const unsubscribe = actionDispatcher.subscribe(handleAction);
    return () => unsubscribe();
  }, []);

  const [openDialog, setOpenDialog] = React.useState<DialogActionsType>(null);

  const closeDialog = () => {
    setOpenDialog(null);
  };

  const onDeleteRecord = async () => {
    setIsDeleting(true);
    try {
      await deleteRecordRequest.call(record.id);
      notifications.success(`Record deleted`);

      if (returnTo) {
        return navigate(returnTo);
      }

      const recordsList = formatRoutePath(Routes.Records, {
        siteId: record.siteId,
      });
      navigate(recordsList);

      closeDialog();
    } catch {
      notifications.error("Error while deleting record");
    } finally {
      setIsDeleting(false);
    }
  };

  const onCloseOpenRecordDialogComplete = (record: RecordDetailView) => {
    //we have to update the form value manually becuase the status was updated not by the form controls. It's updated by the dialog
    methods.setValue("status", record.model.status);
  };

  const recordIdAndAssets = (recordId: number, recordAssets: AssetDigest[]) => {
    return (
      <Stack direction="row" spacing={1}>
        <Box sx={{ pr: 3, pt: 1 }}>ID: {recordId}</Box>
        <Stack direction="row" sx={{ pt: 1 }}>
          <div>Asset(s):&nbsp; </div>
          <RecordAssets assets={recordAssets} assetDisplayLimit={3} />
        </Stack>
      </Stack>
    );
  };

  const recordIdAndEquipmentHealth = (recordId: number, equipmentHealth: EquipmentHealthViewModel) => {
    return (
      <Stack direction="row" spacing={1} alignItems="center">
        <Box sx={{ pr: 3, pt: 1 }}>ID: {recordId}</Box>
        <Box sx={{ pr: 3, pt: 1 }}>Equipment: {equipmentHealth.equipmentName}</Box>
        <Box sx={{ pr: 3, pt: 1 }}>
          <Typography component="span">
            Packaged Solution:&nbsp;
            <InternalLink
              to={formatRoutePath(Routes.PackagedSolution, {
                siteId: equipmentHealth.packageSolution.siteId,
                id: equipmentHealth.packageSolution.id,
              })}
            >
              {equipmentHealth.packageSolution.name}
            </InternalLink>
          </Typography>
        </Box>
      </Stack>
    );
  };

  return (
    <>
      {isMobileView ? (
        <Stack direction="column" spacing={2}>
          <Stack direction="row" alignContent="space-between" alignItems="center">
            <RecordIcon sx={{ fontSize: "28px" }} />
            <Box
              sx={{
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
                fontWeight: (theme) => theme.typography.h5.fontWeight,
                fontFamily: (theme) => theme.typography.h5.fontFamily,
                fontSize: (theme) => theme.typography.h5.fontSize,
                ml: 1,
              }}
            >
              {record.description}
            </Box>
            <Box sx={{ display: "flex", flexGrow: 1, justifyContent: "flex-end" }}>
              <ActionsDropdownMenu primary actions={actions} />
            </Box>
          </Stack>
          <LastModified lastModifiedBy={recordDetail.lastModifiedBy} lastModified={recordDetail.lastModified} />
          <Stack direction="row" spacing={1}>
            <RecordTypeBadge type={record.recordType} />
            <RecordStatusBadge status={record.status} />
          </Stack>
          {recordIdAndAssets(record.id, record.assets)}
        </Stack>
      ) : (
        <Stack direction="row" justifyContent="space-between" pb={2}>
          <Stack direction="column" spacing={2}>
            <Typography variant="h5">
              <Stack direction="row" flexWrap="wrap" spacing={1} alignItems="center">
                <RecordIcon /> <div>{record.description}</div>
              </Stack>
            </Typography>
            <Stack direction="row" spacing={2}>
              <RecordTypeBadge type={record.recordType} />
              {record.recordType === RecordType.ValveDiagnostic && (
                <DiagnosticTypeBadge diagnosticType={record.diagnosticType} />
              )}
            </Stack>

            <Stack direction="row" flexWrap="wrap">
              <Box sx={{ pr: 3, pt: 1 }}>
                <RecordStatusBadge status={record.status} />
              </Box>
              {record.recordType === RecordType.EquipmentHealth && equipmentHealth
                ? recordIdAndEquipmentHealth(record.id, equipmentHealth)
                : recordIdAndAssets(record.id, record.assets)}
            </Stack>
          </Stack>
          <Stack spacing={2} alignItems="flex-end">
            <ActionsDropdownMenu primary actions={actions} />
            <div>
              <LastModified lastModifiedBy={recordDetail.lastModifiedBy} lastModified={recordDetail.lastModified} />
            </div>
          </Stack>
        </Stack>
      )}

      {openDialog === "closeRecord" && (
        <CloseReopenRecordDialog
          onClose={closeDialog}
          record={recordDetail!}
          onComplete={onCloseOpenRecordDialogComplete}
        />
      )}
      {openDialog === "generateReport" && <GenerateReportDialog onClose={closeDialog} record={recordDetail!} />}
      {openDialog === "duplicateRecord" && <DuplicateRecordDialog onClose={closeDialog} record={recordDetail!} />}
      {openDialog === "changeType" && <ChangeRecordTypeDialog onClose={closeDialog} record={recordDetail!} />}
      {openDialog === "editDescription" && (
        <EditFieldDialog
          methods={methods}
          saveChanges={props.saveChanges}
          fieldName="description"
          dialogTitle="Edit record description"
          label="Record Description"
          confirmButtonText="Change description"
          onClose={closeDialog}
        />
      )}

      <ConfirmationDialog
        title="Delete record?"
        open={openDialog === "deleteRecord"}
        cancel={() => closeDialog()}
        loading={isDeleting}
        confirmButtonText="Delete"
        confirm={onDeleteRecord}
      >
        <Typography variant="body2">Are you sure you want to delete this record?</Typography>
      </ConfirmationDialog>
    </>
  );
};
