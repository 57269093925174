import { PackagedSolutionEquipmentModel } from "@bluemarvel/iris-common/model";
import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { IrisColors } from "app/mui/theme";
import { useRemovePackagedSolutionEquipmentRequest } from "packagedSolution/api";
import { useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";

interface RemoveEquipmentDialogProps extends BaseDialogProps {
  equipment: PackagedSolutionEquipmentModel;
}

export const RemoveEquipmentDialog = (props: RemoveEquipmentDialogProps) => {
  const { equipment } = props;

  const [removeText, setRemoveText] = useState("");
  const removePackagedSolutionEquipmentRequest = useRemovePackagedSolutionEquipmentRequest();

  const onSubmit = () => {
    removePackagedSolutionEquipmentRequest.call(equipment.id).then(() => handleClose());
  };

  const handleClose = () => {
    props.onClose();
  };

  return (
    <BaseDialog onClose={handleClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Remove Equipment
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "150px" }}>
        <Stack px={1} spacing={2}>
          {equipment.latestRecord && (
            <Paper sx={{ backgroundColor: "#FDF4E7" }} elevation={0}>
              <Grid container p={2}>
                <Grid item xs={1}>
                  <WarningAmberIcon sx={{ height: "32px", color: "#F29F3E" }} />
                </Grid>
                <Grid item xs={11}>
                  <Typography color={"#603E11"} variant="h6">
                    Warning
                  </Typography>
                </Grid>
                <Grid item xs={1}></Grid>
                <Grid item xs={11}>
                  <Typography color={"#603E11"}>
                    This equipment is currently associated with an existing equipment record. Deleting it will also
                    remove the associated equipment record, along with any linked historical records.
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
          )}
          <Typography variant="body2">
            Are you sure you want to delete {equipment.name} from the packaged solution?
          </Typography>
          <Stack>
            <Typography color="primary.main" variant="caption">
              Type REMOVE to confirm
            </Typography>
            <TextField
              value={removeText}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setRemoveText(event.target.value);
              }}
              size="small"
              sx={{
                "&.MuiTextField-root .MuiOutlinedInput-root": {
                  backgroundColor: IrisColors.gray100,
                  "& fieldset": {
                    borderColor: IrisColors.gray300,
                  },
                  "&:hover fieldset": {
                    borderColor: IrisColors.gray500,
                  },
                  "&.Mui-focused fieldset": {
                    borderColor: IrisColors.blueSpartan,
                  },
                },
              }}
            />
          </Stack>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button onClick={onSubmit} variant="contained" color="error" disabled={removeText !== "REMOVE"}>
            Remove
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
