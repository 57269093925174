import { yupResolver } from "@hookform/resolvers/yup";
import { Button, DialogActions, DialogContent, DialogTitle, Stack } from "@mui/material";
import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import { FormProvider, useForm } from "react-hook-form";
import * as yup from "yup";

import TextAreaFormItem from "app/mui/forms/Input/TextAreaFormItem";
import { RecommendationModel } from "@bluemarvel/iris-common/model";
import { useUpdateRecommendationRequest } from "recommendations/api";

interface EditRecommendationTitleDialogProps extends BaseDialogProps {
  recommendation: RecommendationModel;
}

const validationSchema = yup.object({
  title: yup.string().nullable().required("Required"),
});

export const EditRecommendationTitleDialog = (props: EditRecommendationTitleDialogProps) => {
  const methods = useForm({ resolver: yupResolver(validationSchema), defaultValues: props.recommendation });
  const updateRecommendationRequest = useUpdateRecommendationRequest();

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      if (values.title?.trim() !== props.recommendation.title?.trim()) {
        updateRecommendationRequest.call(values).then(() => props.onClose());
      }
    })();
  };

  return (
    <BaseDialog onClose={props.onClose}>
      <DialogTitle
        sx={{
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          overflow: "hidden",
        }}
      >
        Rename title
      </DialogTitle>
      <DialogContent dividers={true} sx={{ minHeight: "100px" }}>
        <FormProvider {...methods}>
          <Stack spacing={1}>
            <TextAreaFormItem minRows={3} label="Title" fieldName="title" />
          </Stack>
        </FormProvider>
      </DialogContent>
      <DialogActions>
        <Stack direction="row" spacing={2}>
          <Button onClick={props.onClose}>Cancel</Button>
          <Button variant="contained" color="secondary" onClick={onSubmit}>
            Confirm
          </Button>
        </Stack>
      </DialogActions>
    </BaseDialog>
  );
};
