import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { ValveSpec } from "assets/models/asset";
import { checkAssetType } from "assets/utilities";
import { SpecOptionLoader } from "assets/mui/sections/specifications/SpecOptionLoader";
import { EditableAutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import AutoSave from "app/mui/forms/AutoSave";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateValveSpecificationRequest } from "assets/api";
import { useUpdateRecordAssetSpecificationsRequest } from "records";
import { RecordAssetSpecificationCard } from "./RecordAssetSpecificationCard";
import { AssetType } from "assets/models/assetType";

interface ValveSpecificationCardProps {
  assetType: AssetType;
  valve: ValveSpec;
  assetSpec?: ValveSpec;
  recordId?: number;
}

export const ValveSpecificationCard = (props: ValveSpecificationCardProps) => {
  const { assetType, valve, assetSpec, recordId } = props;
  const synchronizable = !!assetSpec && !!recordId;
  const updateSpecificationRequest = useUpdateValveSpecificationRequest();
  const updateRecordAssetSpecificationsRequest = useUpdateRecordAssetSpecificationsRequest();

  const defaultValues = makeSafeForReactHookForm(valve);
  const methods = useForm({ defaultValues });

  const updateAsset = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const updateRecord = () => {
    if (synchronizable) {
      methods.handleSubmit((values) => {
        updateRecordAssetSpecificationsRequest.call({
          recordId: recordId,
          assetId: values.assetId,
          valve: values,
        });
      })();
    }
  };

  const { isAdvancedValve, isReliefValve } = checkAssetType(assetType);

  const standardFields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
  ];

  const advancedValveFields = isAdvancedValve
    ? [
        <SpecOptionLoader type="ValveOrElementSize">
          {(options) => <EditableAutocompleteFormItem label="Size" fieldName="size" freeSolo {...options} />}
        </SpecOptionLoader>,
        <SpecOptionLoader type="PressureClass">
          {(options) => (
            <EditableAutocompleteFormItem label="Pressure class" fieldName="pressureClass" freeSolo {...options} />
          )}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Rated travel" fieldName="ratedTravel" />,
        <EditableTextFieldFormItem label="Seat material" fieldName="seatMaterial" />,
        <SpecOptionLoader type="LeakClass">
          {(options) => <EditableAutocompleteFormItem label="Leak class" fieldName="leakClass" freeSolo {...options} />}
        </SpecOptionLoader>,

        <SpecOptionLoader type="PortSize">
          {(options) => <EditableAutocompleteFormItem label="Port Size" fieldName="portSize" freeSolo {...options} />}
        </SpecOptionLoader>,

        <EditableTextFieldFormItem label="Body material" fieldName="bodyMaterial" />,

        <SpecOptionLoader type="TrimStyle">
          {(options) => (
            <EditableAutocompleteFormItem label="Trim style/number" fieldName="trimStyleNumber" freeSolo {...options} />
          )}
        </SpecOptionLoader>,

        <EditableTextFieldFormItem label="Plug/disc/gate/ball material" fieldName="plugDiscGateBallMaterial" />,
        <EditableTextFieldFormItem label="Stem/shaft material" fieldName="stemShaftMaterial" />,

        <SpecOptionLoader type="StemDiameter">
          {(options) => (
            <EditableAutocompleteFormItem label="Stem diameter" fieldName="stemDiameter" freeSolo {...options} />
          )}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Cage material" fieldName="cageMaterial" />,

        <SpecOptionLoader type="PackingTypeMaterial">
          {(options) => (
            <EditableAutocompleteFormItem
              label="Packing type/material"
              fieldName="packingTypeMaterial"
              freeSolo
              {...options}
            />
          )}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Process fluid" fieldName="processFluid" />,
      ]
    : [];

  const reliefValveFields = isReliefValve
    ? [
        <EditableTextFieldFormItem label="Capacity" fieldName="capacity" />,

        <SpecOptionLoader type="CapacityUnits">
          {(options) => <EditableAutocompleteFormItem label="Capacity units" fieldName="capacityUnits" {...options} />}
        </SpecOptionLoader>,

        <EditableTextFieldFormItem label="Set pressure" fieldName="setPressure" />,
        <SpecOptionLoader type="SetPressureUnits">
          {(options) => (
            <EditableAutocompleteFormItem label="Set pressure units" fieldName="setPressureUnits" {...options} />
          )}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Inlet size" fieldName="inletSize" />,

        <SpecOptionLoader type="SizeUnits">
          {(options) => (
            <EditableAutocompleteFormItem label="Inlet size units" fieldName="inletSizeUnits" {...options} />
          )}
        </SpecOptionLoader>,

        <SpecOptionLoader type="InletOutletType">
          {(options) => (
            <EditableAutocompleteFormItem label="Inlet rating/type" fieldName="inletRatingType" {...options} />
          )}
        </SpecOptionLoader>,

        <EditableTextFieldFormItem label="Outlet size" fieldName="outletSize" />,

        <SpecOptionLoader type="SizeUnits">
          {(options) => (
            <EditableAutocompleteFormItem label="Outlet size units" fieldName="outletSizeUnits" {...options} />
          )}
        </SpecOptionLoader>,

        <SpecOptionLoader type="InletOutletType">
          {(options) => (
            <EditableAutocompleteFormItem label="Outlet rating/type" fieldName="outletRatingType" {...options} />
          )}
        </SpecOptionLoader>,
        <EditableTextFieldFormItem label="Orifice size/letter" fieldName="orificeSizeLetter" />,
      ]
    : [];

  return (
    <PermissionAwareFormProvider {...methods} require={(permission) => permission.userCanEditAsset(assetType)}>
      {synchronizable ? (
        <>
          <AutoSave onSubmit={updateRecord} />
          <RecordAssetSpecificationCard
            title="Valve"
            specs={[...standardFields, ...advancedValveFields, ...reliefValveFields]}
            syncToAsset={updateAsset}
            synced={JSON.stringify(assetSpec) === JSON.stringify(valve)}
          />
        </>
      ) : (
        <>
          <AutoSave onSubmit={updateAsset} />
          <AssetSpecificationCard
            title="Valve"
            specs={[...standardFields, ...advancedValveFields, ...reliefValveFields]}
          />
        </>
      )}
    </PermissionAwareFormProvider>
  );
};
