import { AssetSpecificationCard } from "./AssetSpecificationCard";
import { EditableTextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { EditableActiveObsoleteFormItem } from "app/mui/forms/ActiveObsoleteFormItem";
import { Asset } from "assets/models/asset";
import { makeSafeForReactHookForm } from "app/mui/forms/utils";
import { useForm } from "react-hook-form";
import { PermissionAwareFormProvider } from "app/mui/forms/PermissionAwareFormProvider";
import { useUpdateGeneralSpecificationRequest } from "assets/api";
import AutoSave from "app/mui/forms/AutoSave";
import { GeneralSpecificationModel } from "@bluemarvel/iris-common/model";

interface GeneralSpecificationCardProps {
  asset: Asset;
  general: GeneralSpecificationModel;
}

export const GeneralSpecificationCard = (props: GeneralSpecificationCardProps) => {
  const updateSpecificationRequest = useUpdateGeneralSpecificationRequest();
  const defaultValues = makeSafeForReactHookForm(props.general);
  const methods = useForm({ defaultValues });

  const updateGeneral = () => {
    methods.handleSubmit((values) => {
      updateSpecificationRequest.call(values);
    })();
  };

  const fields = [
    <EditableActiveObsoleteFormItem label="Status" fieldName="isObsolete" />,
    <EditableTextFieldFormItem label="Manufacturer" fieldName="manufacturer" />,
    <EditableTextFieldFormItem label="Model" fieldName="model" />,
    <EditableTextFieldFormItem label="Serial number" fieldName="serialNumber" />,
    <EditableTextFieldFormItem label="Size" fieldName="size" />,
    <EditableTextFieldFormItem label="Custom 1" fieldName="custom1" />,
    <EditableTextFieldFormItem label="Custom 2" fieldName="custom2" />,
    <EditableTextFieldFormItem label="Custom 3" fieldName="custom3" />,
  ];

  return (
    <PermissionAwareFormProvider
      {...methods}
      require={(permission) => permission.userCanEditAsset(props.asset.assetType)}
    >
      <AutoSave onSubmit={updateGeneral} />
      <AssetSpecificationCard title="General" specs={fields} />
    </PermissionAwareFormProvider>
  );
};
