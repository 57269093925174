import {
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
} from "app";
import { RecordFlagType } from "flags";
import {
  RecordOutageDetail,
  RecordOutageDetailsLookupValuesViewModel,
  RecordOutageDetailView,
  RecordOutageEvaluationConsideration,
  RecordOutagePlanningScope,
  RecordOutagePlanningScopeView,
} from "records/bs/outage/models";
import {
  RecordHistoryEvent,
  RecommendationFilters,
  Record,
  RecordDetailView,
  RecordDigest,
  RecordFilters,
  RecordsListFilterLookupValuesViewModel,
  RecordType,
  Status,
} from "./models";
import { ReportCardPneumaticPartialStroke } from "./models/reportCardPneumaticPartialStroke";
import { ReportCardPneumaticFullStroke } from "./models/reportCardPneumaticFullStroke";
import { ReportCardMotorOperated } from "./models/reportCardMotorOperated";
import { AssetHistoryEvent } from "assets/models/historyEvent";
import { ReportCardVibrationAnalysis } from "./models/reportCardVibrationAnalysis";
import { EquipmentHealthReportCardModel, EquipmentHealthViewModel } from "@bluemarvel/iris-common/model";
import { RecordAssetSpecificationsModel } from "./models/recordAssetSpecifications";

export const useGetRecordDigestsRequest = (ids: number[]) =>
  useBaseAxiosGetRequest<RecordDigest[]>(!!ids && ids.length > 0 ? `api/records/digests?ids=${ids.join(",")}` : null, {
    useNode: true,
  });

export const useGetRecordByIdQuery = (id: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordDetailView>(
    `/api/records/details/${id}`,
    recordsMutationUriMatchPatterns,
    { useNode: true }
  );

export const useGetRecordByNextRecordIdQuery = (id: number) =>
  useBaseAxiosGetRequest<RecordDetailView | null>(`/api/records/details/by/nextRecord/${id}`, { useNode: true });

export const useGetRecordsByIdQuery = (ids: number[]) =>
  useBaseAxiosGetRequest<RecordDetailView[]>(
    !!ids && ids.length > 0 ? `/api/records/details?ids=${ids.join(",")}` : null,
    { useNode: true }
  );

export const usePostRecordsByIdQuery = () =>
  useBaseAxiosPostRequest<number[], RecordDetailView[]>(`/api/records/details`, {
    app: { hideFromAutosaver: true },
    useNode: true,
  });

export const useGetRecordsByAssetQuery = (assetIds: number[]) =>
  useBaseAxiosGetRequest<RecordDetailView[]>(
    !!assetIds && assetIds.length > 0 ? `/api/records/by/asset?ids=${assetIds.join(",")}` : null,
    { useNode: true }
  );

export const useGetRecordDetailsByKpiTemplateQuery = (kpiTemplateId: string | null) =>
  useBaseAxiosGetRequest<RecordDetailView[]>(!!kpiTemplateId ? `/api/records/by/kpiTemplate/${kpiTemplateId}` : null, {
    useNode: true,
  });

export const useGetRecordDetailsByRecordsEventQuery = (recordsEventId: number | null) =>
  useBaseAxiosGetRequest<RecordDetailView[]>(
    !!recordsEventId ? `/api/records/by/recordsEvent/${recordsEventId}` : null,
    { useNode: true }
  );

export interface GetFilteredRecordsRequest {
  filters?: RecordFilters;
}

export const useGetRecordDetailsByFilterRequest = (siteId: number) =>
  useBaseAxiosPostRequest<GetFilteredRecordsRequest, RecordDetailView[]>(`api/records/details/filtered/${siteId}`, {
    app: { hideFromAutosaver: true },
    useNode: true,
  });

export const recordsMutationUriMatchPatterns = [
  /api\/records.*/gi,
  /api\/recommendations\/by\/record.*/gi,
  /api\/recordOutageDetails.*/gi,
  /api\/recordHistory.*/gi,
  /api\/records\/changeRecordType.*/gi,
  /api\/reportCards\/equipmentHealth/gi,
];

export const recordOutageDetailsMutationUriMatchPatterns = [/api\/recordOutageDetails.*/gi];

export const recordHistoryMutationUriMatchPatterns = [/api\/recordHistory.*/gi];

export const kpiValuesMutationUriMatchPatterns = [/api\/kpiValues.*/gi];

export interface ChangeRecordTypeRequest {
  recordId: number;
  recordType: RecordType;
}

export interface ChangeRecordFlagsRequest {
  recordId: number;
  flagTypes: RecordFlagType[];
}

export interface CreateRecordAssetSpecificationsRequest {
  recordId: number;
  assetIds: number[];
}

export const useChangeRecordTypeRequest = () =>
  useBaseAxiosPutRequest<ChangeRecordTypeRequest>(`/api/records/changeRecordType`, { useNode: true });

export interface CloseOrReOpenRecordRequest {
  recordId: number;
  status: Status;
  comment: string;
}

export const useCloseOrOpenRecordRequest = () =>
  useBaseAxiosPostRequest<CloseOrReOpenRecordRequest, RecordDetailView>(`/api/records/closeOrReopen`, {
    useNode: true,
  });
export const useCreateRecordRequest = () =>
  useBaseAxiosPostRequest<{ record: Partial<Record>; resolution: string | null }, RecordDetailView>(`/api/records`, {
    useNode: true,
  });
export const useDeleteRecordRequest = () =>
  useBaseAxiosDeleteRequest<{}>((id) => `/api/records/${id}`, { useNode: true });
export const useDuplicateRecordRequest = () =>
  useBaseAxiosPostRequest<
    {
      record: Record;
      duplicateKpis: boolean;
      duplicateBudgetaryReplacementCost: boolean;
      duplicateOriginalScopeOfWork: boolean;
      duplicateScopeRecommendations: boolean;
    },
    RecordDetailView
  >(`/api/records/duplicate`, { useNode: true });

export const useImportRecordsRequest = () => useBaseAxiosPostRequest<Record[]>(`/api/records/import`);

export const useChangeRecordFlags = () =>
  useBaseAxiosPutRequest<ChangeRecordFlagsRequest, RecordFlagType[]>(`/api/records/changeRecordFlags`, {
    useNode: true,
  });

export const useSnoozeRecordRequest = () => useBaseAxiosPostRequest<Record, Record>(`/api/records/snooze`);
export const useUpdateRecordRequest = () => useBaseAxiosPutRequest<Record, void>(`/api/records`, { useNode: true });

export const useGetFilterLookupValuesForSiteQuery = (siteId: number) =>
  useBaseAxiosGetRequest<RecordsListFilterLookupValuesViewModel>(`/api/records/filterLookupValuesForSite/${siteId}`);
export const useGetUsedAssetIdsOnOutageScopeRecordsByRecordsEventId = (recordsEventId: number | null) =>
  useBaseAxiosGetRequest<number[]>(
    !!recordsEventId ? `/api/records/usedAssetIdsOnOutageScopeRecordsByRecordsEventId/${recordsEventId}` : null,
    { useNode: true }
  );
export const useGetUsedRecordsEventIdsOnOutageScopeRecordsByAssetId = (assetId: number | null) =>
  useBaseAxiosGetRequest<number[]>(
    !!assetId ? `/api/records/usedRecordsEventIdsOnOutageScopeRecordsByAssetId/${assetId}` : null,
    { useNode: true }
  );

export const useGetRecordOutageDetailViewByRecordIdQuery = (recordId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordOutageDetailView>(
    `/api/recordOutageDetails/${recordId}`,
    [/api\/recordOutageDetails.*/],
    { useNode: true }
  );
export const useUpdateRecordOutageDetailRequest = () =>
  useBaseAxiosPutRequest<RecordOutageDetail>(`/api/recordOutageDetails`, { useNode: true });
export const useGetRecordOutageDetailsLookupValuesByRecordsEventIdQuery = (recordsEventId: number) =>
  useBaseAxiosGetRequest<RecordOutageDetailsLookupValuesViewModel>(
    `/api/recordOutageDetails/lookupValuesByRecordsEventId/${recordsEventId}`,
    { useNode: true }
  );

export const useGetOutagePlanningScopesByRecordIdQuery = (recordId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordOutagePlanningScopeView[]>(
    `/api/recordOutageDetails/${recordId}/scopes`,
    [/api\/recordOutageDetails\/.*/],
    { useNode: true }
  );
export const useCreateOutagePlanningScopeRequest = (recordId: number) =>
  useBaseAxiosPostRequest<RecordOutagePlanningScope>(`/api/recordOutageDetails/${recordId}/scopes`, { useNode: true });
export const useUpdateOutagePlanningScopeRequest = (recordId: number) =>
  useBaseAxiosPutRequest<RecordOutagePlanningScope>(`/api/recordOutageDetails/${recordId}/scopes`, { useNode: true });
export const useDeleteOutagePlanningScopeRequest = (recordId: number) =>
  useBaseAxiosDeleteRequest<number>((scopeId) => `/api/recordOutageDetails/${recordId}/scopes/${scopeId}`, {
    useNode: true,
  });

export const useGetOutagEvaluationConsiderationsByRecordIdQuery = (recordId: number) =>
  useBaseAxiosGetRequest<RecordOutageEvaluationConsideration[]>(
    `/api/recordOutageDetails/${recordId}/evaluationConsiderations`
  );
export const useCreateOutageEvaluationConsiderationRequest = (recordId: number) =>
  useBaseAxiosPostRequest<Partial<RecordOutageEvaluationConsideration>>(
    `/api/recordOutageDetails/${recordId}/evaluationConsiderations`,
    { useNode: true }
  );
export const useUpdateOutageEvaluationConsiderationRequest = (recordId: number) =>
  useBaseAxiosPutRequest<RecordOutageEvaluationConsideration>(
    `/api/recordOutageDetails/${recordId}/evaluationConsiderations`,
    { useNode: true }
  );
export const useDeleteOutageEvaluationConsiderationRequest = (recordId: number) =>
  useBaseAxiosDeleteRequest<number>(
    (evaluationId) => `/api/recordOutageDetails/${recordId}/evaluationConsiderations/${evaluationId}`,
    { useNode: true }
  );

export interface GetFilteredRecommendationsRequest {
  filters?: RecommendationFilters;
}

// ReportCards
export const useUpdateReportCardPneumaticFullStroke = () =>
  useBaseAxiosPutRequest<ReportCardPneumaticFullStroke>(`api/reportCards/pneumaticFullStroke`, {
    useNode: true,
  });
export const useUpdateReportCardPneumaticPartialStroke = () =>
  useBaseAxiosPutRequest<ReportCardPneumaticPartialStroke>(`api/reportCards/pneumaticPartialStroke`, {
    useNode: true,
  });
export const useUpdateReportCardMotorOperated = () =>
  useBaseAxiosPutRequest<ReportCardMotorOperated>(`api/reportCards/motorOperated`, {
    useNode: true,
  });
export const useUpdateReportCardVibrationAnaylsis = () =>
  useBaseAxiosPutRequest<ReportCardVibrationAnalysis>(`api/reportCards/vibrationAnalysis`, {
    useNode: true,
  });
export const useUpdateReportCardEquipmentHealth = () =>
  useBaseAxiosPutRequest<EquipmentHealthReportCardModel>(`api/reportCards/equipmentHealth`, {
    useNode: true,
  });

// History Events
export const useGetRecordHistoryByRecordIdRequest = (recordId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordHistoryEvent[]>(
    `api/recordHistory/${recordId}`,
    [...recordsMutationUriMatchPatterns, ...kpiValuesMutationUriMatchPatterns],
    {
      useNode: true,
    }
  );

export const useCreateRecordHistoryRequest = () =>
  useBaseAxiosPostRequest<Partial<RecordHistoryEvent>, RecordHistoryEvent>(`api/recordHistory`, {
    useNode: true,
  });

export const useUpdateRecordHistoryRequest = () =>
  useBaseAxiosPutRequest<RecordHistoryEvent>(`api/recordHistory`, {
    useNode: true,
  });

export const useGetAssetHistoryByAssetIdRequest = (assetId: number) =>
  useBaseAxiosGetRequest<AssetHistoryEvent[]>(`api/assetHistory/${assetId}`, {
    useNode: true,
  });

export const useGetEquipmentHealthRequest = (recordId: number) =>
  useBaseAxiosGetRequest<EquipmentHealthViewModel>(`api/packagedSolutions/equipmentHealth/${recordId}`, {
    useNode: true,
    axios: {
      manual: true,
    },
  });

export const useGetRecordAssetSpecificationsRequest = (recordId: number) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecordAssetSpecificationsModel[]>(
    `api/assetSpecifications/record/${recordId}`,
    [/api\/assetSpecifications\/record/],
    { useNode: true }
  );

export const useCreateRecordAssetSpecificationsRequest = () =>
  useBaseAxiosPostRequest<CreateRecordAssetSpecificationsRequest>(`api/assetSpecifications/record`, {
    useNode: true,
  });

export const useUpdateRecordAssetSpecificationsRequest = () =>
  useBaseAxiosPutRequest<RecordAssetSpecificationsModel>(`api/assetSpecifications/record`, {
    useNode: true,
  });
