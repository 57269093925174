import { GridApiPro } from "@mui/x-data-grid-pro/models/gridApiPro";
import { GridInitialStatePro } from "@mui/x-data-grid-pro/models/gridStatePro";
import { Site } from "sites";

export interface CustomDataView {
  id: number;
  name: string;
  view: string;
  type: string & CustomDataViewType;
  siteId: number | null;
  customerId: number | null;
  userId: string | null;
  shared: boolean;
  created: Date;
}

export type CustomDataViewType =
  | "Assets"
  | "Assets-ControlValve"
  | "Assets-ReliefValve"
  | "Assets-Regulator"
  | "Assets-Measurement"
  | "Assets-ManualValve"
  | "Assets-Machinery"
  | "Assets-IsolationValve"
  | "Assets-SteamTrap"
  | "Assets-MotorOperatedValve"
  | "Machinery-Components"
  | "Assets-Tank"
  | "Assets-General"
  | "Records"
  | "Asset-Records"
  | "Summary-Records-With-Assets"
  | "Summary-Records-Without-Assets"
  | "Kpi-Template-Records"
  | "Summary-Assets"
  | "High-Risk-Assets"
  | "Recommendations"
  | "KPIs";

export const createDefaultEmptyTableState = (apiRef: React.MutableRefObject<GridApiPro>): GridInitialStatePro => {
  const allColumnsHidden = {};
  const orderedFields: string[] = [];

  apiRef.current
    .getAllColumns()
    .map((c) => c.field)
    .forEach((column) => {
      allColumnsHidden[column] = false;
      orderedFields.push(column);
    });

  return {
    columns: {
      columnVisibilityModel: { ...allColumnsHidden, identifier: true, actions: true },
      orderedFields: orderedFields,
      dimensions: {},
    },
    detailPanel: undefined,
    filter: { filterModel: { items: [] } },
    pagination: {},
    pinnedColumns: { right: ["actions"] },
    preferencePanel: undefined,
    sorting: { sortModel: [] },
  };
};

export enum CustomDataViewScopes {
  Everywhere = "everywhere",
  ThisCustomer = "this-customer",
  ThisSite = "this-site",
}

export const getCustomDataViewScopeOptions = (site: Site) => [
  { label: "All sites", id: CustomDataViewScopes.Everywhere },
  { label: `All sites within ${site.customer.name}`, id: CustomDataViewScopes.ThisCustomer },
  { label: `Only this site (${site.name})`, id: CustomDataViewScopes.ThisSite },
];

export const importCustomDataViewScopeOptions = (site: Site) => [
  { label: "All sites", id: CustomDataViewScopes.Everywhere },
  { label: `All sites within ${site.customer.name}`, id: CustomDataViewScopes.ThisCustomer },
  { label: `Only this site (${site.name})`, id: CustomDataViewScopes.ThisSite },
];
