import { CreateRecommendationModel, RecommendationModel } from "@bluemarvel/iris-common/model";
import {
  useBaseAxiosGetRequest,
  useBaseAxiosPostRequest,
  useBaseAxiosPutRequest,
  useBaseAxiosDeleteRequest,
  useBaseAxiosGetRequestWithAutoRefresh,
} from "app";
import { RecommendationDetailView, GetFilteredRecommendationsRequest, Recommendation } from "records";

export const useGetRecommendationDetailsBySiteRequest = (siteId: number) =>
  useBaseAxiosGetRequest<RecommendationDetailView[]>(`api/recommendations/details/by/site/${siteId}`, {
    useNode: true,
  });

export const useGetFilteredRecommendationDetailsBySiteRequest = (siteId: number) =>
  useBaseAxiosPostRequest<GetFilteredRecommendationsRequest | null, RecommendationDetailView[]>(
    `api/recommendations/details/by/site/${siteId}`,
    { app: { hideFromAutosaver: true }, useNode: true }
  );

export const useCreateRecommendationRequest = () =>
  useBaseAxiosPostRequest<CreateRecommendationModel, RecommendationModel>(`api/recommendations`, {
    useNode: true,
  });

export const useUpdateRecommendationRequest = () =>
  useBaseAxiosPutRequest<RecommendationModel>(`api/recommendations`, {
    useNode: true,
  });

export const useDeleteRecommendationRequest = () =>
  useBaseAxiosDeleteRequest((id) => `api/recommendations/${id}`, { useNode: true });

export const useGetRecommendationsRequest = ({
  recordId,
  packagedSolutionId,
}: {
  recordId?: number;
  packagedSolutionId?: number;
}) =>
  useBaseAxiosGetRequestWithAutoRefresh<RecommendationModel[]>(
    `/api/recommendations?${recordId ? "recordId=" + recordId : ""}${
      packagedSolutionId ? "packagedSolutionId=" + packagedSolutionId : ""
    }`,
    [/api\/recommendations.*/],
    { useNode: true }
  );
