import {
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { MultiAssetSelectFormItem } from "app/mui/forms/MultiAssetSelectFormItem";

import { BaseDialog, BaseDialogProps } from "app/mui/common/dialogs/BaseDialog";
import {
  OperatingSystemModel,
  PackagedSolutionEquipmentModel,
  PackagedSolutionEquipmentUpdateModel,
  PackagedSolutionEquipmentUpdateSchema,
} from "@bluemarvel/iris-common/model";
import { TextFieldFormItem } from "app/mui/forms/Input/TextFieldFormItem";
import { AutocompleteFormItem } from "app/mui/forms/AutocompleteFormItem";
import { useUpdatePackagedSolutionEquipmentRequest } from "packagedSolution/api";
import { zodResolver } from "@hookform/resolvers/zod";
import { useState } from "react";
import WarningAmberIcon from "@mui/icons-material/WarningAmber";
import { IrisColors } from "app/mui/theme";

const DialogTitleStyles = {
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
};

interface EditEquipmentDialogProps extends BaseDialogProps {
  equipment: PackagedSolutionEquipmentModel;
  operatingSystems: OperatingSystemModel[];
}

export const EditEquipmentDialog = (props: EditEquipmentDialogProps) => {
  const { equipment, operatingSystems } = props;

  const [assets, setAssets] = useState(equipment.assets);
  const [removeText, setRemoveText] = useState("");
  const methods = useForm<PackagedSolutionEquipmentUpdateModel>({
    defaultValues: equipment,
    resolver: zodResolver(PackagedSolutionEquipmentUpdateSchema),
  });
  const watchAssets = methods.watch("assets");
  const deleteAssets = assets.find((a) => !watchAssets.some((w) => a.id === w.id));

  const updatePackagedSolutionEquipmentRequest = useUpdatePackagedSolutionEquipmentRequest();

  const onSubmit = () => {
    methods.handleSubmit((values) => {
      updatePackagedSolutionEquipmentRequest.call(values).then(() => {
        handleClose();
      });
    })();
  };

  const handleClose = () => {
    methods.reset();
    props.onClose();
  };

  const handleResetRemoveAsset = () => {
    setRemoveText("");
    methods.setValue("assets", assets);
  };

  const handleConfirmRemoveAsset = () => {
    setRemoveText("");
    setAssets(watchAssets);
  };

  return (
    <>
      <BaseDialog onClose={handleClose}>
        <DialogTitle sx={DialogTitleStyles}>Edit Equipment</DialogTitle>
        <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
          <FormProvider {...methods}>
            <Stack spacing={1}>
              <TextFieldFormItem label="Name" fieldName="name" />
              <MultiAssetSelectFormItem label="Asset(s)" fieldName="assets" selectedAssets={[]} />
              <AutocompleteFormItem
                autocompleteProps={{ disablePortal: false }}
                label="Operating System"
                fieldName="operatingSystemId"
                options={operatingSystems.map((os) => ({ id: os.id, label: os.name }))}
              />
            </Stack>
          </FormProvider>
        </DialogContent>
        <DialogActions>
          <Stack direction="row" spacing={2}>
            <Button onClick={props.onClose}>Cancel</Button>
            <Button onClick={onSubmit} variant="contained" color="secondary">
              Done
            </Button>
          </Stack>
        </DialogActions>
      </BaseDialog>
      {deleteAssets && (
        <BaseDialog onClose={handleResetRemoveAsset}>
          <DialogTitle sx={DialogTitleStyles}>Remove Asset</DialogTitle>
          <DialogContent dividers={true} sx={{ minHeight: "300px" }}>
            <Stack spacing={2}>
              <Paper sx={{ backgroundColor: "#FDF4E7" }} elevation={0}>
                <Grid container p={2}>
                  <Grid item xs={1}>
                    <WarningAmberIcon sx={{ height: "32px", color: "#F29F3E" }} />
                  </Grid>
                  <Grid item xs={11}>
                    <Typography color={"#603E11"} variant="h6">
                      Warning
                    </Typography>
                  </Grid>
                  <Grid item xs={1}></Grid>
                  <Grid item xs={11}>
                    <Typography color={"#603E11"}>
                      This asset is currently linked to an existing equipment record. Removing it will also delete its
                      associated specifications and calibration data from that record. It is advised to create a new
                      equipment record before removing the asset.
                    </Typography>
                  </Grid>
                </Grid>
              </Paper>
              <Stack px={1} spacing={2}>
                <Typography>
                  Are you sure you want to remove {deleteAssets.tag} from {equipment.name}?
                </Typography>
                <Stack>
                  <Typography color="primary.main" variant="caption">
                    Type REMOVE to confirm
                  </Typography>
                  <TextField
                    value={removeText}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      setRemoveText(event.target.value);
                    }}
                    size="small"
                    sx={{
                      "&.MuiTextField-root .MuiOutlinedInput-root": {
                        backgroundColor: IrisColors.gray100,
                        "& fieldset": {
                          borderColor: IrisColors.gray300,
                        },
                        "&:hover fieldset": {
                          borderColor: IrisColors.gray500,
                        },
                        "&.Mui-focused fieldset": {
                          borderColor: IrisColors.blueSpartan,
                        },
                      },
                    }}
                  />
                </Stack>
              </Stack>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Stack direction="row" spacing={2}>
              <Button onClick={handleResetRemoveAsset}>Cancel</Button>
              <Button
                onClick={handleConfirmRemoveAsset}
                variant="contained"
                color="error"
                disabled={removeText !== "REMOVE"}
              >
                Remove
              </Button>
            </Stack>
          </DialogActions>
        </BaseDialog>
      )}
    </>
  );
};
