import { GeneralSubtype } from "@bluemarvel/iris-common/model";
import { Badge, BadgeProps } from "../Badge";

interface Props extends Omit<BadgeProps, "text"> {
  generalSubtype: GeneralSubtype | null;
  subtypeDescription: string | null;
}

export const GeneralSubtypeBadge = (props: Props) => {
  const { generalSubtype, subtypeDescription } = props;
  const subtypeLabel =
    generalSubtype === "Other" && subtypeDescription ? `${generalSubtype} - ${subtypeDescription}` : generalSubtype;

  if (subtypeLabel == null) {
    return <></>;
  }

  return <Badge text={subtypeLabel}></Badge>;
};
