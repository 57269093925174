import { useUserContext } from "app";
import { AssetType } from "assets/models/assetType";
import { AttachmentParent } from "attachments/api";
import { RecordType } from "records";

export enum Permissions {
  ViewAsset = "view:asset",
  /** add any asset type */
  AddAsset = "add:asset",
  AddInstrumentationAsset = "add:instrumentation-asset",
  AddMachineryAsset = "add:machinery-asset",
  GenerateAssetReport = "generate:asset-report",
  //** edit any asset type */
  EditAsset = "edit:asset",
  EditInstrumentationAsset = "edit:instrumentation-asset",
  EditMachineryAsset = "edit:machinery-asset",

  ViewRecord = "view:record",
  //** edit any record type except valve diagnostic records */
  EditRecord = "edit:record",
  EditInstrumentationRecord = "edit:instrumentation-record",
  EditMachineryRecord = "edit:machinery-record",
  EditValveDiagnosticRecord = "edit:valveDiagnosticRecord",
  ReopenRecord = "reopen:record",
  AddComments = "add:comments",
  ViewInternalHistoryEvents = "view:internal-history-events",
  GenerateRecordReport = "generate:record-report",
  EditRecordFlags = "edit:record-flags",

  ViewKpi = "view:kpi",
  EditKpi = "edit:kpi",
  ViewKpiTemplate = "view:kpi-template",
  EditKpiTemplate = "edit:kpi-template",

  ViewAssetList = "view:asset-list",
  ExportAssetList = "export:asset-list",
  ExportAssetAttachment = "export:asset-attachment",
  ViewRecordList = "view:record-list",
  ExportRecordList = "export:record-list",
  ViewOtherSites = "view:other-sites",
  EditOtherSites = "edit:other-sites",

  ViewApplication = "view:application",
  EditApplication = "edit:application",
  GenerateApplicationReport = "generate:application-report",

  SearchAnything = "search:anything",

  ViewSiteDashboard = "view:site-dashboard",
  ViewSiteDashboardReports = "view:site-dashboard-reports",

  ManageCustomersAndSites = "manage:customers-and-sites",
  ManageAreas = "manage:areas",
  ManageKeywords = "manage:keywords",
  AssignUsersToSites = "assign:users-to-sites",
  ImportAssetsAndRecords = "import:assets-and-records",
  UnlockKpi = "unlock:kpi",

  ViewSummary = "view:summary",
  EditSummary = "edit:summary",
  GenerateSummaryReport = "generate:summary-report",

  ViewPackagedSolution = "view:packaged-solution",
  EditPackagedSolution = "edit:packaged-solution",

  EditReleaseNotes = "edit:release-notes",
}

export interface UserPermissions {
  userCanViewAsset: boolean;

  userCanAddAssets: boolean;
  userCanAddMachineryAsset: boolean;
  userCanAddInstrumentationAsset: boolean;
  userCanAddAsset: (assetType: AssetType) => boolean;
  userCanAddAnyAsset: boolean;
  userCanEditAssets: boolean;
  userCanEditAsset: (assetType: AssetType) => boolean;

  userCanGenerateAssetReport: boolean;

  userCanViewRecord: boolean;

  userCanEditRecords: boolean;
  userCanEditRecord: (assetTypes: AssetType[], recordType: RecordType) => boolean;

  userCanReopenRecord: boolean;
  userCanEditRecordsEvents: boolean;

  userCanAddComments: boolean;
  userCanViewInternalHistoryEvents: boolean;
  userCanGenerateRecordReport: boolean;
  userCanEditRecordFlags: boolean;
  userCanViewKpi: boolean;
  userCanEditKpi: boolean;
  userCanViewKpiTemplate: boolean;
  userCanEditKpiTemplate: boolean;
  userCanViewAssetList: boolean;
  userCanExportAssetList: boolean;
  userCanExportAssetAttachment: boolean;
  userCanViewRecordList: boolean;
  userCanExportRecordList: boolean;
  userCanViewOtherSites: boolean;
  userCanEditOtherSites: boolean;
  userCanViewApplication: boolean;
  userCanEditApplication: boolean;
  userCanGenerateApplicationReport: boolean;
  userCanSearchAnything: boolean;
  userCanViewSiteDashboard: boolean;
  userCanViewSiteDashboardReports: boolean;
  userCanManageCustomersAndSites: boolean;
  userCanManageAreas: boolean;
  userCanManageKeywords: boolean;
  userCanAssignUsersToSites: boolean;
  userCanImportAssetsAndRecords: boolean;
  userCanUnlockKpi: boolean;
  userCanViewSummary: boolean;
  userCanEditSummary: boolean;
  userCanGenerateSummaryReport: boolean;
  userCanViewPackagedSolution: boolean;
  userCanEditPackagedSolution: boolean;
  userCanEditReleaseNotes: boolean;
}

export interface User {
  userId: string;
  fullName: string;
  email: string;
  picture: string;
}

export interface UserWithPermissions extends User {
  permissions: Permissions[];
  siteIds: number[];
}

export const useCanEditAttachment = (type: AttachmentParent): boolean => {
  const { userPermissions } = useUserContext();

  switch (type) {
    case "Application":
      return userPermissions.userCanEditApplication;
    case "Asset":
      return userPermissions.userCanEditAssets; // these checks are technically insufficient, because technically users might not be able to edit all asset /records types
    case "Record":
      return userPermissions.userCanEditRecords;
    case "Summary":
      return userPermissions.userCanEditSummary;
    case "PackagedSolution":
      return userPermissions.userCanEditPackagedSolution;
  }

  return false;
};
